import React from "react";
import { AboutUsComponent, LocationComponent } from "../../components";
import "./styles.scss";
import { useEffect } from "react";
import { redirectPath } from "../../utilities/helpers";

function AboutUs() {
  useEffect(() => {
    redirectPath("/AboutUs");
  }, []);
  return (
    <div className="about-us-container">
      <AboutUsComponent />
      <LocationComponent />
    </div>
  );
}

export default AboutUs;
