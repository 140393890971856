import React, { useState } from "react";
import { TitleComponent } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";
import { useSelector } from "react-redux";

const contentMap = {
  0: require("../../assets/images/About-Us-Section-1.png"),
  1: require("../../assets/images/About-Us-Boyko-Web-Image-1.png"),
  3: require("../../assets/images/4.png"),
  4: require("../../assets/images/5.png"),
};

function AboutUsComponent() {
  const textMap = [translate("aboutUsOption1"), translate("aboutUsOption2"), translate("aboutUsOption4"), translate("aboutUsOption5")];
  const language = useSelector(({ starter }) => starter?.language) || "";
  const [selected, setSelected] = useState(0);
  return (
    <div className="about-us-component" style={{ background: "#070e22" }}>
      {window.innerWidth < 900 ? (
        <>
          <div className="top-mobile-container">
            <TitleComponent title={translate("aboutUs")} noline={false} specialLine />
          </div>
          {Object.keys(contentMap).map((key, index) => {
            return (
              <div className="about-us-mobile-element" style={{ marginBottom: index < Object.keys(contentMap)?.length - 1 && "50px" }}>
                <p style={{ padding: "0px 5%", fontWeight: "600" }}>{textMap[index]}</p>
                {index === 0 ? (
                  <p style={{ padding: "15px 5%" }}>{translate("aboutUsDescription")}</p>
                ) : index === 1 ? (
                  <>
                    <p style={{ padding: "15px 5%" }}>{translate("aboutUsDescription2-1")}</p>
                    <p style={{ padding: "15px 5%" }}>{translate("aboutUsDescription2-2")}</p>
                  </>
                ) : index === 2 ? (
                  <p style={{ padding: "15px 5%" }}>{translate("aboutUsDescription3")}</p>
                ) : (
                  <p style={{ padding: "15px 5%" }}>{translate("aboutUsDescription4")}</p>
                )}
                <div className="image" style={{ backgroundImage: `url(${contentMap[key]})` }} />
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div className="picture-container" style={{ backgroundImage: `url(${contentMap[selected]})` }} />
          <div className="right-container">
            <div className="inner-right-container">
              <TitleComponent title={translate("aboutUs")} />
              <div className="tab-rows-container">
                <p id={selected === 0 && "selected"} className="about-us-header" onClick={() => setSelected(0)}>
                  {translate("aboutUsOption1")}
                </p>
                {selected === 0 && <p className="about-us-body">{translate("aboutUsDescription")}</p>}

                <p id={selected === 1 && "selected"} onClick={() => setSelected(1)}>
                  {translate("aboutUsOption2")}
                </p>
                {selected === 1 && (
                  <>
                    <p className="about-us-body">{translate("aboutUsDescription2-1")}</p>
                    <p className="about-us-body">{translate("aboutUsDescription2-2")}</p>
                  </>
                )}
                <p id={selected === 3 && "selected"} onClick={() => setSelected(3)}>
                  {translate("aboutUsOption4")}
                </p>
                {selected === 3 && <p className="about-us-body">{translate("aboutUsDescription3")}</p>}

                <p id={selected === 4 && "selected"} onClick={() => setSelected(4)}>
                  {translate("aboutUsOption5")}
                </p>
                {selected === 4 && <p className="about-us-body">{translate("aboutUsDescription4")}</p>}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUsComponent;
