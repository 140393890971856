import React, { useEffect, useState } from "react";
import { TitleComponent } from "../../components";
import { onSetBlogContent } from "../../actions";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import "./styles.scss";
import { translate } from "../../translations/localization";

function AboutUsGrid({ onSetBlogContent }) {
  const language = window.localStorage.getItem("immoLanguage");

  return (
    <div className="about-us-grid">
      <div className="grid-element one">
        <div className="inner-grid-container">
          <TitleComponent
            title={translate("1name")}
            small
          />
          <p>
           {translate("1text")[0]}
          </p>
          <div
            className="read-more-button"
            onClick={() => {
              history.push({
                pathname: '/SingleBlog',
                search: "1",
            })
              window.scrollTo({ top: 65, behavior: "smooth" });
            }}
          >
            <p>{language === "en" ? "Read More" : "Прочети повече"}</p>
            <div className="green-arrow-right" />
          </div>
        </div>
      </div>
      <div className="grid-element two">
        <div className="inner-grid-container">
          <TitleComponent
            title={translate("2name")}
            small
          />
          <p>{translate("2text")[0]}</p>
          <div
            className="read-more-button"
            onClick={() => {
              history.push({
                pathname: '/SingleBlog',
                search: "2",
            })
              window.scrollTo({ top: 65, behavior: "smooth" });
            }}
          >
            <p>{language === "en" ? "Read More" : "Прочети повече"}</p>
            <div className="green-arrow-right" />
          </div>
        </div>
      </div>
      <div className="grid-element three">
        <div className="inner-grid-container">
          <TitleComponent
          title={
            translate("3name")
            }
            small
          />
          <p>
           {translate("3text")[0]}
            
          </p>
          <div
            className="read-more-button"
            onClick={() => {
              history.push({
                pathname: '/SingleBlog',
                search: "3",
            })
              window.scrollTo({ top: 65, behavior: "smooth" });
            }}
          >
            <p>{language === "en" ? "Read More" : "Прочети повече"}</p>
            <div className="green-arrow-right" />
          </div>
        </div>
      </div>
      <div className="grid-element four">
        <div className="inner-grid-container">
          <TitleComponent
            title={translate("4name")}
            small
          />
          <p>
            {translate("4text")[0]}
          </p>
          <div
            className="read-more-button"
            onClick={() => {
              history.push({
                pathname: '/SingleBlog',
                search: "4",
            })
              window.scrollTo({ top: 65, behavior: "smooth" });
            }}
          >
            <p>{language === "en" ? "Read More" : "Прочети повече"}</p>
            <div className="green-arrow-right" />
          </div>
        </div>
      </div>
      <div className="grid-element five">
        <div className="inner-grid-container">
          <TitleComponent
            title={translate("5name")}
            small
          />
          <p>
            {translate("5text")[0]}
          </p>
          <div
            className="read-more-button"
            onClick={() => {
              history.push({
                pathname: '/SingleBlog',
                search: "5",
            })
              window.scrollTo({ top: 65, behavior: "smooth" });
            }}
          >
            <p>{language === "en" ? "Read More" : "Прочети повече"}</p>
            <div className="green-arrow-right" />
          </div>
        </div>
      </div>
      <div className="grid-element six">
        <div className="inner-grid-container">
          <TitleComponent
            title={translate("6name")}
            small
          />
          <p>
          {translate("6text")[0]}
          </p>
          <div
            className="read-more-button"
            onClick={() => {
              history.push({
                pathname: '/SingleBlog',
                search: "6",
            })
              window.scrollTo({ top: 65, behavior: "smooth" });
            }}
          >
            <p>{language === "en" ? "Read More" : "Прочети повече"}</p>
            <div className="green-arrow-right" />
          </div>
        </div>
      </div>
      <div className="grid-element seven">
        <div className="inner-grid-container">
          <TitleComponent
            title={translate("7name")}
            small
          />
          <p>
            {translate("7text")[0]}
          </p>
          <div
            className="read-more-button"
            onClick={() => {
              history.push({
                pathname: '/SingleBlog',
                search: "7",
            })
              window.scrollTo({ top: 65, behavior: "smooth" });
            }}
          >
            <p>{language === "en" ? "Read More" : "Прочети повече"}</p>
            <div className="green-arrow-right" />
          </div>
        </div>
      </div>
      <div className="grid-element eight">
        <div className="inner-grid-container">
          <TitleComponent
            title={translate("8name")}
            small
          />
          <p>
          {translate("8text")[0]}
          </p>
          <div
            className="read-more-button"
            onClick={() => {
              history.push({
                pathname: '/SingleBlog',
                search: "8",
            })
              window.scrollTo({ top: 65, behavior: "smooth" });
            }}
          >
            <p>{language === "en" ? "Read More" : "Прочети повече"}</p>
            <div className="green-arrow-right" />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  onSetBlogContent: (payload) => dispatch(onSetBlogContent(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUsGrid);
