import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { BlogTypes, stopLoading, startLoading } from "../actions";
import { ajax } from "rxjs/ajax";
import { API_URL, basicAuth } from "../config/settings";

  export const getArticles = (action$) => 
   action$.pipe(
      ofType(BlogTypes.GET_ARTICLES),
      switchMap(({ payload }) => {
        return ajax({
            url: `${API_URL}/admin/articles/list`,
            method: "POST",
            headers: basicAuth,
            body: JSON.stringify(payload)
          }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next(startLoading())
              obs.next({
                type: BlogTypes.GET_ARTICLES_SUCCESS,
                payload: response.articles,
              })
              // obs.next(stopLoading())
              obs.complete()
            })
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.log(err);
            //   onError(err);
              obs.complete();
            });
          })
        )
      })
    )

  export const getArticle = (action$) => 
   action$.pipe(
      ofType(BlogTypes.GET_ARTICLE),
      switchMap(({ payload }) => {
        return ajax({
            url: `${API_URL}/admin/articles/${payload?.articleId}`,
            method: "GET",
            headers: basicAuth,
            body: JSON.stringify(payload)
          }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: BlogTypes.GET_ARTICLE_SUCCESS,
                payload: response.article,
              })
              obs.complete()
            })
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.log(err);
            //   onError(err);
              obs.complete();
            });
          })
        )
      })
    )
  
