import React, { Component, useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { Router, useLocation } from "react-router-dom";
import { configureStore, history } from "./config/stores";
import { Routes } from "./components/routes/Routes";
import "./App.scss";
import { setLanguageLocale } from "./actions";

let store = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  );
};
// class App extends Component {
//   render() {
//     return (
//       <Provider store={store}>
//         <Router history={history}>
//           <Routes />
//         </Router>
//       </Provider>
//     );
//   }
// }

export default App;
