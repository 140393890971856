import React, { useEffect, useState } from "react";
import { TitleComponent, Tabs } from "../../components";
import "./styles.scss";
import { translate } from "../../translations/localization";
const indexMap = {
  1: "One",
  2: "Two",
  3: "Three",
};

function TabsComponent({ title, subtitle, tabs, tabsText, backgroundImages, light, video, divider }) {
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const language = window.localStorage.getItem("immoLanguage");

  useEffect(() => {
    tabs && setSelectedTab(tabs[0]);
    backgroundImages && setSelectedImage(backgroundImages[0]);
  }, [language]);

  useEffect(() => {
    if (tabs?.length) {
      let currentIndex = tabs.findIndex((el) => el === selectedTab);
      setSelectedImage(backgroundImages[currentIndex]);
    }
  }, [selectedTab]);

  return (
    <div className={`tabs-component-container mobile ${divider && window.innerWidth < 900 && "divider"} ${light ? "light" : ""}`}>
      {window.innerWidth < 900 ? (
        <>
          <TitleComponent title={title} ml={5} />
          {subtitle && <h3 className="inner-title">{subtitle}</h3>}
          {tabsText?.map((tab, index) => {
            return (
              <>
                <div className={`text-container ${index !== 0 && "margin"}`}>
                  <h3>{translate(`Step${[index + 1]}`)}</h3>
                  <p>{tab}</p>
                </div>
                <div className="image" style={{ backgroundImage: `url(${language === "en" ? backgroundImages[index] : backgroundImages[index]})` }} />
              </>
            );
          })}
        </>
      ) : (
        <>
          <div className="content-container">
            <div className="text-inner-container">
              <TitleComponent title={title} />
              {subtitle && <h3 className="inner-title">{subtitle}</h3>}
              <Tabs tabs={tabs} selected={selectedTab} onSelect={(value) => setSelectedTab(value)} />
              {tabs?.map((tab, index) => {
                if (tab === selectedTab) return <p>{tabsText[index] || ""}</p>;
                else return "";
              })}
            </div>
          </div>
          {language === "en" || !video ? <div className="image" style={{ backgroundImage: `url(${selectedImage})` }} /> : <div className="image" style={{ backgroundImage: `url(${selectedImage})` }} />}

          {video && (
            <div className="video-container">
              <video playsInline={true} muted={true} preload="auto" loop={true} autoPlay={true}>
                <source src={selectedImage} type="video/mp4" />
              </video>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TabsComponent;
