import React from "react";
import { BlogComponent } from "../../components";
import "./styles.scss";

function SingleBlog() {
  return (
    <div className="single-blog-container">
      <BlogComponent />
    </div>
  );
}

export default SingleBlog;
