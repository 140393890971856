import React, { useState } from "react";
import { GoogleMaps, Tabs, TitleComponent } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

function LocationComponent() {
  const [imageView, setImageView] = useState(false);

  const [selectedTab, setSelectedTab] = useState(translate("аboutUsTab1"));

  return (
    <div className="location-component-container">
      <div className="left-container">
        <div className="inner-text-container">
          <div className="flex-container">
            <TitleComponent title={translate("aboutUsLocation")} noline={false} specialLine />
          </div>
          {window.innerWidth < 900 ? (
            <>
              <b className="country-header">{translate("аboutUsTab1")}</b>
              <div className="country-green-line" />
              <p style={{ fontWeight: "550", fontSize: "18px", textTransform: "uppercase", paddingBottom: "16px" }}>{translate("address")}</p>
              <p style={{ paddingBottom: "5px" }}>{translate("aboutUsStreet")}</p>
              <p style={{ paddingBottom: "5px" }}>1407</p>
              <p style={{ paddingBottom: "5px" }}>{translate("aboutUsRegion")?.split(",")?.[0]}</p>
              <p style={{ paddingBottom: "5px" }}>({translate("aboutUsOfficeA")})</p>
              <p style={{ fontWeight: "500", fontSize: "18px", marginTop: "20px", textTransform: "uppercase", paddingBottom: "16px" }}>{translate("contacts")}</p>
              <div className="flex-container mobile gap no-margin">
                <p>{translate("aboutUsEmail")}</p>
                <p>{translate("aboutUsPhoneNumber")}</p>
              </div>
              <div style={{ width: "100%", marginLeft: "-17px" }}>
                <GoogleMaps address={"ИМОТЕХ"} location={{ lat: 42.66756601490748, lng: 23.328878446693754 }} />
              </div>
              <b className="country-header" style={{ marginTop: "40px" }}>
                {translate("аboutUsTab2")}
              </b>
              <div className="country-green-line" />
              <p style={{ fontWeight: "550", fontSize: "18px", textTransform: "uppercase", paddingBottom: "16px" }}>{translate("address")}</p>
              <p style={{ paddingBottom: "5px" }}>{translate("aboutUsAddressGermany")}</p>
              <p style={{ paddingBottom: "5px" }}>60549</p>
              <p style={{ paddingBottom: "5px" }}>{translate("aboutUsCityGermany")}</p>
              <p style={{ fontWeight: "500", fontSize: "18px", marginTop: "20px", textTransform: "uppercase", paddingBottom: "16px" }}>{translate("contacts")}</p>
              <div className="flex-container mobile gap no-margin">
                <p>{translate("aboutUsEmail")}</p>
                <p>{translate("aboutUsGermanyPhoneNumber")}</p>
              </div>
              <div style={{ width: "100%", marginLeft: "-17px" }}>
                <GoogleMaps address={"ИМОТЕХ"} location={{ lat: 50.05473625199093, lng: 8.596001768346976 }} />
              </div>
            </>
          ) : (
            <>
              <Tabs
                wide
                tabs={[translate("аboutUsTab1"), translate("аboutUsTab2")]}
                style={{ justifyContent: "flex-start" }}
                tabStyle={{ width: "50%", display: "flex", justifyContent: "center" }}
                selected={selectedTab}
                onSelect={(value) => {
                  setSelectedTab(value);
                }}
              />
              {selectedTab === translate("аboutUsTab1") ? (
                <>
                  <p style={{ fontWeight: "550", fontSize: "24px", textTransform: "uppercase" }}>{translate("address")}</p>
                  <p style={{ paddingBottom: "16px" }}>{translate("aboutUsStreet")}</p>
                  <p style={{ paddingBottom: "16px" }}>1407</p>
                  <p style={{ paddingBottom: "16px" }}>{translate("aboutUsRegion")?.split(",")?.[0]}</p>
                  <p style={{ paddingBottom: "16px" }}>({translate("aboutUsOfficeA")})</p>
                </>
              ) : (
                <>
                  <p style={{ fontWeight: "550", fontSize: "24px", textTransform: "uppercase" }}>{translate("address")}</p>
                  <p style={{ paddingBottom: "16px" }}>{translate("aboutUsAddressGermany")}</p>
                  <p style={{ paddingBottom: "16px" }}>60549</p>
                  <p style={{ paddingBottom: "16px" }}>{translate("aboutUsCityGermany")}</p>
                </>
              )}
              <p style={{ fontWeight: "500", fontSize: "24px", marginTop: "20px", textTransform: "uppercase" }}>{translate("contacts")}</p>
              <div className="flex-container mobile gap no-margin">
                <p>{translate("aboutUsEmail")}</p>
                {selectedTab === translate("аboutUsTab1") ? <p>{translate("aboutUsPhoneNumber")}</p> : <p>{translate("aboutUsGermanyPhoneNumber")}</p>}
              </div>
            </>
          )}
        </div>
      </div>
      {window.innerWidth > 900 ? (
        imageView ? (
          <div className="image" />
        ) : selectedTab === translate("аboutUsTab1") ? (
          <GoogleMaps address={"ИМОТЕХ"} location={{ lat: 42.66756601490748, lng: 23.328878446693754 }} /> //bg
        ) : (
          <GoogleMaps address={"ИМОТЕХ"} location={{ lat: 50.05473625199093, lng: 8.596001768346976 }} /> // de
        )
      ) : null}
    </div>
  );
}

export default LocationComponent;
