import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import './styles.scss';

export default function FunctionalitiesCalendar() {
    const language = window.localStorage.getItem("immoLanguage");

    const [selectedTab, setSelectedTab] = useState(
      translate("functionalitiesSectionCalendarTabOne")
    );
    const [currentImage, setCurrentImage] = useState(
      language === "bg"
        ? require("../../assets/images/Calendar-add-event.png")
        : translate("functionalityCalendarImageTabOne")
    );
  
    useEffect(() => {
      setSelectedTab(translate("functionalitiesSectionCalendarTabOne"));
      if (language === 'bg') setCurrentImage(require("../../assets/images/Calendar-add-event.png"))
      else translate("functionalityCalendarImageTabOne")
    }, [language]);
  
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("functionalitiesSectionCalendar")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesSectionCalendarTabOne")}
          </h3>
          {translate("functionalitiesSectionCalendarTabOneDescription").map((el, index) => {
            return (
              <div className="line-and-text" key={`${el}-index`}>
                <div className="left-line" />
                <p>{translate("functionalitiesSectionCalendarTabOneDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Calendar-add-event.png")
                : translate("functionalityCalendarImageTabOne")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesSectionCalendarTabTwo")}
          </h3>
          {translate("functionalitiesSectionCalendarTabTwoDescription").map((el, index) => {
            return (
              <div className="line-and-text" key={`${el}-0-index`}>
                <div className="left-line" />
                <p>{translate("functionalitiesSectionCalendarTabTwoDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Calendar-shared.png")
                : translate("functionalityCalendarImageTabTwo")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesSectionCalendarTabThree")}
          </h3>
          {translate("functionalitiesSectionCalendarTabThreeDescription").map((el, index) => {
            return (
              <div className="line-and-text" key={`${el}-1-index`}>
                <div className="left-line" />
                <p>{translate("functionalitiesSectionCalendarTabThreeDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Calendar-search.png")
                : translate("functionalityCalendarImageTabThree")
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="functionalities-section-screen functionalities-calendar-container">
      <div
        className="left-container"
        style={{ backgroundImage: `url(${currentImage})` }}
      ></div>
      <div className="right-container">
        <div
          className="back-btn"
          onClick={() => history.push("/Functionality")}
        >
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <div className="inner-container">
          <TitleComponent title={translate("functionalitiesSectionCalendar")} />
          <Tabs
            wide
            tabs={[
              translate("functionalitiesSectionCalendarTabOne"),
              translate("functionalitiesSectionCalendarTabTwo"),
              translate("functionalitiesSectionCalendarTabThree")
            ]}
            selected={selectedTab}
            onSelect={(value) => {
              // setCurrentImage(null);
              setTimeout(() => {
                if (value === translate("functionalitiesSectionCalendarTabOne")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Calendar-add-event.png")
                    : translate("functionalityCalendarImageTabOne")
                  );
                } else if (value === translate("functionalitiesSectionCalendarTabTwo")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Calendar-shared.png")
                    : translate("functionalityCalendarImageTabTwo")
                  );
                } else if (value === translate("functionalitiesSectionCalendarTabThree")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Calendar-search.png")
                    : translate("functionalityCalendarImageTabThree")
                  );
                }
              }, 10);
              setSelectedTab(value);
            }}
          />
          {selectedTab === translate("functionalitiesSectionCalendarTabOne") ? (
            <>
              {translate("functionalitiesSectionCalendarTabOneDescription").map((el, index) => {
                return (
                  <div className="line-and-text" key={`${el}-2-index`}>
                    <div className="left-line" />
                    <p>{translate("functionalitiesSectionCalendarTabOneDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("functionalitiesSectionCalendarTabTwo") ? (
            <>
              {translate("functionalitiesSectionCalendarTabTwoDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text" key={`${el}-3-index`}>
                      <div className="left-line" />
                      <p>
                        {translate("functionalitiesSectionCalendarTabTwoDescription")[index]}
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesSectionCalendarTabThree") ? (
            <>
              {translate("functionalitiesSectionCalendarTabThreeDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text" key={`${el}-4-index`}>
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesSectionCalendarTabThreeDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
