import { combineReducers } from "redux";
// import { createNavigationReducer } from 'react-navigation-redux-helpers'
import { routerReducer } from "react-router-redux";

import starter from "./starter";
import blog from "./blog";

export default combineReducers({
  starter,
  routerReducer,
  blog,
});
