export const BlogTypes = {
  SET_BLOG_CONTENT: "blog/SET_BLOG_CONTENT",
  GET_ARTICLES: "blog/GET_ARTICLES",
  GET_ARTICLES_SUCCESS: "blog/GET_ARTICLES_SUCCESS",
  GET_ARTICLE: "blog/GET_ARTICLE",
  GET_ARTICLE_SUCCESS: "blog/GET_ARTICLE_SUCCESS",
};


export const onSetBlogContent = (payload) => ({
  type: BlogTypes.SET_BLOG_CONTENT,
  payload,
});

export const getArticles = (payload) => ({
  type: BlogTypes.GET_ARTICLES,
  payload,
});

export const getArticlesSuccess = (payload) => ({
  type: BlogTypes.GET_ARTICLES_SUCCESS,
  payload,
});

export const getArticle = (payload) => ({
  type: BlogTypes.GET_ARTICLE,
  payload,
});

export const getArticleSuccess = (payload) => ({
  type: BlogTypes.GET_ARTICLE_SUCCESS,
  payload,
});
