import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import "./styles.scss";

function SmartProfile() {
  const [selectedTab, setSelectedTab] = useState(
    translate("smartProfileStatus")
  );
  const [currentImage, setCurrentImage] = useState(
    "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-profile-1.png"
  );

  const language = window.localStorage.getItem("immoLanguage");

  useEffect(() => {
    setSelectedTab(translate("smartProfileStatus"));
  }, [language]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("smartProfile")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("smartProfileStatus")}
          </h3>
          {translate("smartProfileStatusDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("smartProfileStatusDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-my-profile-1-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-profile-1.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("smartProfileActivity")}
          </h3>
          {translate("smartProfileActivityDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("smartProfileActivityDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-my-profile-2-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-profile-2.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("smartProfileFiltration")}
          </h3>
          {translate("smartProfileFiltrationDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("smartProfileFiltrationDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-my-profile-3-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-profile-3.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("smartProfileMap")}
          </h3>
          {translate("smartProfileMapDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("smartProfileMapDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-my-profile-4-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-profile-4.png"
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="smart-profile-container">
      <div
        className="left-container"
        style={{ backgroundImage: `url(${currentImage})` }}
      ></div>
      <div className="right-container">
        <div
          className="back-btn"
          onClick={() => history.push("/Functionality")}
        >
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <div className="inner-container">
          <TitleComponent title={translate("smartProfile")} />
          <Tabs
            wide
            tabs={[
              translate("smartProfileStatus"),
              translate("smartProfileActivity"),
              translate("smartProfileFiltration"),
              translate("smartProfileMap"),
            ]}
            selected={selectedTab}
            onSelect={(value) => {
              setCurrentImage(null);
              setTimeout(() => {
                if (value === translate("smartProfileStatus")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-my-profile-1-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-profile-1.png"
                  );
                } else if (value === translate("smartProfileActivity")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-my-profile-2-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-profile-2.png"
                  );
                } else if (value === translate("smartProfileFiltration")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-my-profile-3-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-profile-3.png"
                  );
                } else if (value === translate("smartProfileMap")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-my-profile-4-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-profile-4.png"
                  );
                }
              }, 10);
              setSelectedTab(value);
            }}
          />
          {selectedTab === translate("smartProfileStatus") ? (
            <>
              {translate("smartProfileStatusDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("smartProfileStatusDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("smartProfileActivity") ? (
            <>
              {translate("smartProfileActivityDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("smartProfileActivityDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("smartProfileFiltration") ? (
            <>
              {translate("smartProfileFiltrationDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {translate("smartProfileFiltrationDescription")[index]}
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("smartProfileMap") ? (
            <>
              {translate("smartProfileMapDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("smartProfileMapDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default SmartProfile;
