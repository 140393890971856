import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import "./styles.scss";

function BusinessUsers() {
  const language = window.localStorage.getItem("immoLanguage");

  const [selectedTab, setSelectedTab] = useState(translate("functionalitiesBusinessUsersTabOne"));
  const [currentImage, setCurrentImage] = useState(
    language === 'bg' ?
    require("../../assets/images/Immotech-webapp-dashboard.png")
    : translate("functionalityBusinessImageTabOne")
  );

  const [selectedTab2, setSelectedTab2] = useState(
    translate("homepageStepOne")
  );
  const [currentVideo, setCurrentVideo] = useState(
    "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-1.mp4"
  );

  useEffect(() => {
    setSelectedTab(translate("functionalitiesBusinessUsersTabOne"));
    setSelectedTab2(translate("homepageStepOne"));
  }, [language]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return window.innerWidth < 900 ? (
    <>
      <div className="smart-profile-mobile-container">
        <div className="back-btn" onClick={() => history.goBack()}>
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <TitleComponent title={translate("functionalitiesBusinessUsersTitle")} />
        <div className="mobile-content">
          <div className="mobile-text">
            <h3 style={{ marginBottom: "10px" }}>
              {translate("functionalitiesBusinessUsersTabOne")}
            </h3>
            {translate("functionalitiesBusinessUsersTabOneDescription")?.map((el, index) => {
              return (
                <div className="line-and-text">
                  <div className="left-line" />
                  <p>{translate("functionalitiesBusinessUsersTabOneDescription")[index]}</p>
                </div>
              );
            })}
          </div>
          <div
            className="mobile-image one"
            style={{
              backgroundImage: `url(${language === 'bg' ? require("../../assets/images/Immotech-webapp-dashboard.png") : translate("functionalityBusinessImageTabOne")})`,
            }}
          ></div>
        </div>

        <div className="mobile-content">
          <div className="mobile-text">
            <h3 style={{ marginBottom: "10px", marginTop: "5px" }}>
              {translate("functionalitiesBusinessUsersTabTwo")}
            </h3>
            {translate("functionalitiesBusinessUsersTabTwoDescription")?.map((el, index) => {
              return (
                <div className="line-and-text">
                  <div className="left-line" />
                  <p>{translate("functionalitiesBusinessUsersTabTwoDescription")[index]}</p>
                </div>
              );
            })}
          </div>
          <div
            className="mobile-image one"
            style={{
              backgroundImage: `url(${language === 'bg' ? require("../../assets/images/Immotech-webapp-property.png") : translate("functionalityBusinessImageTabTwo")})`,
            }}
          ></div>
        </div>

        <div className="mobile-content">
          <div className="mobile-text">
            <h3 style={{ marginBottom: "10px", marginTop: "5px" }}>
              {translate("functionalitiesBusinessUsersTabThree")}
            </h3>
            {translate("functionalitiesBusinessUsersTabThreeDescription")?.map((el, index) => {
              return (
                <div className="line-and-text">
                  <div className="left-line" />
                  <p>{translate("functionalitiesBusinessUsersTabThreeDescription")[index]}</p>
                </div>
              );
            })}
          </div>
          <div
            className="mobile-image one"
            style={{
              backgroundImage: `url(${language === 'bg' ? require("../../assets/images/Immotech-webapp-finance.png") : translate("functionalityBusinessImageTabThree")})`,
            }}
          ></div>
        </div>

        <div className="mobile-content">
          <div className="mobile-text">
            <h3 style={{ marginBottom: "10px", marginTop: "5px" }}>
              {translate("functionalitiesBusinessUsersTabFour")}
            </h3>
            {translate("functionalitiesBusinessUsersTabFourDescription")?.map((el, index) => {
              return (
                <div className="line-and-text">
                  <div className="left-line" />
                  <p>{translate("functionalitiesBusinessUsersTabFourDescription")[index]}</p>
                </div>
              );
            })}
          </div>
          <div
            className="mobile-image one"
            style={{
              backgroundImage: `url(${language === 'bg' ? require("../../assets/images/Immotech-webapp-profile.png") : translate("functionalityBusinessImageTabFour")})`,
            }}
          ></div>
        </div>
      </div>
      <div className="smart-profile-mobiel-container white">
        <TitleComponent title={translate("functionalitiesBusinessUsersTitleSectionTwo")} ml={3} />
        <div className="mobile-content">
          <div className="mobile-text">
            <TitleComponent
              title={translate("homepageStepOne")}
              small
              noline
              ml={3}
            ></TitleComponent>
            <p className="text">{translate("functionalitiesBusinessUsersSectionTwoTabOneDescription")}</p>
          </div>
          <video
            src={
              language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-1-en.mp4" :
              "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-1.mp4"
            }
            className="video-container mobile"
            autoPlay
            muted
            loop
            // webkit-playsInline
            playsInline
          />
        </div>
        <div className="mobile-content" style={{ marginTop: "2%" }}>
          <div className="mobile-text">
            <TitleComponent
              title={translate("homepageStepTwo")}
              small
              noline
              ml={3}
            ></TitleComponent>
            <p className="text">{translate("functionalitiesBusinessUsersSectionTwoTabTwoDescription")}</p>
          </div>
          <video
            src={
              language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-2-en.mp4" :
              "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-2.mp4"
            }
            className="video-container mobile"
            autoPlay
            muted
            loop
            // webkit-playsInline
            playsInline
          />
        </div>
        <div
          className="mobile-content"
          style={{ marginTop: "2%", marginBottom: "-1%" }}
        >
          <div className="mobile-text">
            <TitleComponent
              title={translate("homepageStepThree")}
              small
              noline
              ml={3}
            ></TitleComponent>
            <p className="text">{translate("functionalitiesBusinessUsersSectionTwoTabThreeDescription")}</p>
          </div>
          <video
            src={
              language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-3-en.mp4" :
              "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-3.mp4"
            }
            className="video-container mobile"
            autoPlay
            muted
            loop
            // webkit-playsInline
            playsInline
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="financial-statement-container">
        <div
          className="left-container"
          style={{ backgroundImage: `url(${currentImage})` }}
        ></div>
        <div className="right-container">
          <div
            className="back-btn"
            onClick={() => {
              history.push("/Functionality");
              window.scrollTo(
                Number(localStorage.getItem("lastPositionY")),
                "smooth"
              );
            }}
          >
            <div className="arrow-back" />
            <p>{translate("gobackwards")}</p>
          </div>
          <div className="inner-container">
            <TitleComponent title={translate("functionalitiesBusinessUsersTitle")} />
            <Tabs
              wide
              tabs={[
                translate("functionalitiesBusinessUsersTabOne"),
                translate("functionalitiesBusinessUsersTabTwo"),
                translate("functionalitiesBusinessUsersTabThree"),
                translate("functionalitiesBusinessUsersTabFour"),
              ]}
              selected={selectedTab}
              onSelect={(value) => {
                // setCurrentImage(null);
                setTimeout(() => {
                  if (value === translate("functionalitiesBusinessUsersTabOne")) {
                    setCurrentImage(
                      language === 'bg' ?
                      require("../../assets/images/Immotech-webapp-dashboard.png") : 
                      translate("functionalityBusinessImageTabOne"))
                  } else if (value === translate("functionalitiesBusinessUsersTabTwo")) {
                    setCurrentImage(
                      language === 'bg' ?
                      require("../../assets/images/Immotech-webapp-property.png") : 
                      translate("functionalityBusinessImageTabTwo"))
                  } else if (value === translate("functionalitiesBusinessUsersTabThree")) {
                    setCurrentImage(
                      language === 'bg' ?
                      require("../../assets/images/Immotech-webapp-finance.png") : 
                      translate("functionalityBusinessImageTabThree"))
                  } else if (value === translate("functionalitiesBusinessUsersTabFour")) {
                    setCurrentImage(
                      language === 'bg' ?
                      require("../../assets/images/Immotech-webapp-profile.png") : 
                      translate("functionalityBusinessImageTabFour"))
                  }
                }, 10);
                setSelectedTab(value);
              }}
            />
            {selectedTab === translate("functionalitiesBusinessUsersTabOne") ? (
              <>
                {translate("functionalitiesBusinessUsersTabOneDescription").map((el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>{translate("functionalitiesBusinessUsersTabOneDescription")[index]}</p>
                    </div>
                  );
                })}
              </>
            ) : selectedTab === translate("functionalitiesBusinessUsersTabTwo") ? (
              <>
                {translate("functionalitiesBusinessUsersTabTwoDescription").map((el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>{translate("functionalitiesBusinessUsersTabTwoDescription")[index]}</p>
                    </div>
                  );
                })}
              </>
            ) : selectedTab === translate("functionalitiesBusinessUsersTabThree") ? (
              <>
                {translate("functionalitiesBusinessUsersTabThreeDescription").map((el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>{translate("functionalitiesBusinessUsersTabThreeDescription")[index]}</p>
                    </div>
                  );
                })}
              </>
            ) : selectedTab === translate("functionalitiesBusinessUsersTabFour") ? (
              <>
                {translate("functionalitiesBusinessUsersTabFourDescription").map((el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>{translate("functionalitiesBusinessUsersTabFourDescription")[index]}</p>
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="financial-statement-container reversed">
        <div className="left-container">
          {selectedTab2 === translate("homepageStepOne") ? (
            <video
            src={
              language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-1-en.mp4" :
              "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-1.mp4"
            }
              className="video-container"
              autoPlay
              muted
              loop
              // webkit-playsInline
              playsInline
            />
          ) : selectedTab2 === translate("homepageStepTwo") ? (
            <video
            src={
              language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-2-en.mp4" :
              "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-2.mp4"
            }
              className="video-container"
              autoPlay
              muted
              loop
              // webkit-playsInline
              playsInline
            />
          ) : (
            <video
            src={
              language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-3-en.mp4" :
              "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-3.mp4"
            }
              className="video-container"
              autoPlay
              muted
              loop
              // webkit-playsInline
              playsInline
            />
          )}
        </div>
        <div className="right-container white">
          <div className="inner-container">
            <TitleComponent title={translate("functionalitiesBusinessUsersTitleSectionTwo")} />
            <Tabs
              wide
              tabs={[
                translate("homepageStepOne"),
                translate("homepageStepTwo"),
                translate("homepageStepThree"),
              ]}
              selected={selectedTab2}
              onSelect={(value) => {
                setCurrentVideo(null);
                setTimeout(() => {
                  if (value === translate("homepageStepOne")) {
                    setCurrentVideo(
                      language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-1-en.mp4" :
              "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-1.mp4"
                    );
                  } else if (value === translate("homepageStepTwo")) {
                    setCurrentVideo(
                      language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-2-en.mp4" :
              "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-2.mp4"
                    );
                  } else if (value === translate("homepageStepThree")) {
                    setCurrentVideo(
                      language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-3-en.mp4" :
                      "https://immotech-web.s3.eu-central-1.amazonaws.com/images/STEP-3.mp4"
                    );
                  }
                }, 0);
                setSelectedTab2(value);
              }}
            />
            {selectedTab2 === translate("homepageStepOne") ? (
              <p>{translate("functionalitiesBusinessUsersSectionTwoTabOneDescription")}</p>
            ) : selectedTab2 === translate("homepageStepTwo") ? (
              <>
                <p>{translate("functionalitiesBusinessUsersSectionTwoTabTwoDescription")}</p>
              </>
            ) : selectedTab2 === translate("homepageStepThree") ? (
              <>
                <p>{translate("functionalitiesBusinessUsersSectionTwoTabThreeDescription")}</p>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessUsers;
