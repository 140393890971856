import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent, TabsComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import './styles.scss';

export default function FunctionalitiesBusinessUsers() {
    const language = window.localStorage.getItem("immoLanguage");

    const [selectedTab, setSelectedTab] = useState(
      translate("functionalitiesBusinessUsersTabOne")
    );
    const [currentImage, setCurrentImage] = useState(
      language === "bg"
        ? require("../../assets/images/Calendar-add-event.png")
        : translate("functionalityBusinessImageTabOne")
    );
  
    useEffect(() => {
      setSelectedTab(translate("functionalitiesBusinessUsersTabOne"));
      if (language === 'bg') setCurrentImage(require("../../assets/images/Calendar-add-event.png"))
      else translate("functionalityBusinessImageTabOne")
    }, [language]);
  
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("functionalitiesBusinessUsersTitle")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesBusinessUsersTabOne")}
          </h3>
          {translate("functionalitiesBusinessUsersTabOneDescription").map((el, index) => {
            return (
              <div className="line-and-text" key={`${el}-index`}>
                <div className="left-line" />
                <p>{translate("functionalitiesBusinessUsersTabOneDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? require("../../assets/images/Calendar-add-event.png")
                : require("../../assets/images/Calendar-add-event.png")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesBusinessUsersTabTwo")}
          </h3>
          {translate("functionalitiesBusinessUsersTabTwoDescription").map((el, index) => {
            return (
              <div className="line-and-text" key={`${el}-0-index`}>
                <div className="left-line" />
                <p>{translate("functionalitiesBusinessUsersTabTwoDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? require("../../assets/images/Calendar-shared.png")
                : require("../../assets/images/Calendar-shared.png")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesBusinessUsersTabThree")}
          </h3>
          {translate("functionalitiesBusinessUsersTabThreeDescription").map((el, index) => {
            return (
              <div className="line-and-text" key={`${el}-1-index`}>
                <div className="left-line" />
                <p>{translate("functionalitiesBusinessUsersTabThreeDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? require("../../assets/images/Calendar-search.png")
                : require("../../assets/images/Calendar-search.png")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesBusinessUsersTabFour")}
          </h3>
          {translate("functionalitiesBusinessUsersTabFourDescription").map((el, index) => {
            return (
              <div className="line-and-text" key={`${el}-1-index`}>
                <div className="left-line" />
                <p>{translate("functionalitiesBusinessUsersTabFourDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? require("../../assets/images/Calendar-search.png")
                : require("../../assets/images/Calendar-search.png")
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="functionalities-section-screen functionalities-users-container">
      <div className="functionalities-users-inner-container">
        <div
          className="left-container"
          style={{ backgroundImage: `url(${currentImage})` }}
        ></div>
        <div className="right-container">
          <div
            className="back-btn"
            onClick={() => history.push("/Functionality")}
          >
            <div className="arrow-back" />
            <p>{translate("gobackwards")}</p>
          </div>
          <div className="inner-container">
            <TitleComponent title={translate("functionalitiesBusinessUsersTitle")} />
            <Tabs
              wide
              tabs={[
                translate("functionalitiesBusinessUsersTabOne"),
                translate("functionalitiesBusinessUsersTabTwo"),
                translate("functionalitiesBusinessUsersTabThree"),
                translate("functionalitiesBusinessUsersTabFour"),
              ]}
              selected={selectedTab}
              onSelect={(value) => {
                // setCurrentImage(null);
                setTimeout(() => {
                  if (value === translate("functionalitiesBusinessUsersTabOne")) {
                    setCurrentImage(
                      language === "bg"
                      ? require("../../assets/images/Calendar-add-event.png")
                      : translate("functionalityBusinessImageTabOne")
                    );
                  } else if (value === translate("functionalitiesBusinessUsersTabTwo")) {
                    setCurrentImage(
                      language === "bg"
                      ? require("../../assets/images/Calendar-shared.png")
                      : translate("functionalityBusinessImageTabTwo")
                    );
                  } else if (value === translate("functionalitiesBusinessUsersTabThree")) {
                    setCurrentImage(
                      language === "en"
                      ? require("../../assets/images/Calendar-search.png")
                      : translate("functionalityBusinessImageTabThree")
                    );
                  } else if (value === translate("functionalitiesBusinessUsersTabFour")) {
                    setCurrentImage(
                      language === "en"
                      ? require("../../assets/images/Calendar-search.png")
                      : translate("functionalityBusinessImageTabFour")
                    );
                  }
                }, 10);
                setSelectedTab(value);
              }}
            />
            {selectedTab === translate("functionalitiesBusinessUsersTabOne") ? (
              <>
                {translate("functionalitiesBusinessUsersTabOneDescription").map((el, index) => {
                  return (
                    <div className="line-and-text" key={`${el}-2-index`}>
                      <div className="left-line" />
                      <p>{translate("functionalitiesBusinessUsersTabOneDescription")[index]}</p>
                    </div>
                  );
                })}
              </>
            ) : selectedTab === translate("functionalitiesBusinessUsersTabTwo") ? (
              <>
                {translate("functionalitiesBusinessUsersTabTwoDescription").map(
                  (el, index) => {
                    return (
                      <div className="line-and-text" key={`${el}-3-index`}>
                        <div className="left-line" />
                        <p>
                          {translate("functionalitiesBusinessUsersTabTwoDescription")[index]}
                        </p>
                      </div>
                    );
                  }
                )}
              </>
            ) : selectedTab === translate("functionalitiesBusinessUsersTabThree") ? (
              <>
                {translate("functionalitiesBusinessUsersTabThreeDescription").map(
                  (el, index) => {
                    return (
                      <div className="line-and-text" key={`${el}-4-index`}>
                        <div className="left-line" />
                        <p>
                          {
                            translate("functionalitiesBusinessUsersTabThreeDescription")[
                              index
                            ]
                          }
                        </p>
                      </div>
                    );
                  }
                )}
              </>
            ) : selectedTab === translate("functionalitiesBusinessUsersTabFour") ? (
              <>
                {translate("functionalitiesBusinessUsersTabFourDescription").map(
                  (el, index) => {
                    return (
                      <div className="line-and-text" key={`${el}-4-index`}>
                        <div className="left-line" />
                        <p>
                          {
                            translate("functionalitiesBusinessUsersTabFourDescription")[
                              index
                            ]
                          }
                        </p>
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <TabsComponent
       light
       video
       title={translate("functionalitiesBusinessUsersTitleSectionTwo")}
       tabs={[translate("homepageStepOne"), translate("homepageStepTwo"), translate("homepageStepThree")]}
       tabsText={[
         translate("functionalitiesBusinessUsersSectionTwoTabOneDescription"),
         translate("functionalitiesBusinessUsersSectionTwoTabTwoDescription"),
         translate("functionalitiesBusinessUsersSectionTwoTabThreeDescription"),
       ]}
       backgroundImages={[
         "https://immotech-web.s3.eu-central-1.amazonaws.com/images/Homepage-Section-5-step-1.png",
         "https://immotech-web.s3.eu-central-1.amazonaws.com/images/Homepage-Section-5-step-2.png",
         "https://immotech-web.s3.eu-central-1.amazonaws.com/images/Homepage-Section-5-step-3.png"
       ]}
       />

    </div>
  );
}
