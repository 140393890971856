import React,{useEffect} from "react";
import { history } from "../../config/stores";
import {
  MainComponent,
  PropertyComponent,
  DownloadAppSection,
  ServicesComponent,
  Section
} from "../../components";
import "./styles.scss";
import { translate } from "../../translations/localization";

function Functionality() {

  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  useEffect(()=>{
    let position = Number(localStorage.getItem("lastPositionY"))
    window.scrollTo({
      top:position,
      behavior:"smooth"
    })
  },[])

  return (
    <div className="functionality-container">
   
   {/* old */}
      {/* <Section
      image = {`url(${translate("funcMain1image")})`}
      title={translate("functionalitiesManagingProperty")}
      reversed
      invert
      isMobile={isMobileVersion}
      renderContent={()=>{return(<>
              <div className="line-and-text">
                  <div className="left-line"/>
                  <p className="tab-text">{translate("functionalitiesManagingPropertyDescription1")}</p>
              </div>
              <div className="line-and-text">
                  <div className="left-line"/>
                  <p className="tab-text">{translate("functionalitiesManagingPropertyDescription2")}</p>
              </div> 
              <div className="line-and-text">
                  <div className="left-line"/>
                  <p className="tab-text">{translate("functionalitiesManagingPropertyDescription3")}</p>
              </div> 
              <div className="line-and-text">
                  <div className="left-line"/>
                  <p className="tab-text">{translate("functionalitiesManagingPropertyDescription4")}</p>
              </div>  
              <div className="line-and-text">
                  <div className="left-line"/>
                  <p className="tab-text">{translate("functionalitiesManagingPropertyDescription5")}</p>
              </div>
        </>)
        
      }}
      /> */}

    {/* <ServicesComponent /> */}
    
    {/* <Section
    image={`url(${translate("funcMain3image")})`}
    title={translate("functionalitiesUtilityBills")}
    reversed
    isMobile={isMobileVersion}
    height = {isMobileVersion === 1 && 85}
    renderContent={()=>{
      return(
        <div className="button-and-text">
        <p className="tab-text">{translate("functionalitiesUtilityBillsDescription")}</p>
        <div
              className="button-container"
              onClick={() => {
                localStorage.setItem("lastPositionY",window.scrollY);
                history.push("/UtilityBills");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <p>{translate("readmore")}</p>
              <div className="green-arrow-right" />
            </div>
        </div>
        );
    }}
    /> */}
    
    {/* <MainComponent
      image={`${translate("funcMain4image")}`}
      reversed
      opacity
      left
      mobileRight
      title={translate("functionalitiesFinancialStatement")}
      text={[
        {
          subtitle: "",
          paragraph: translate("functionalitiesFinancialStatementDescription"),
        },
      ]}
      button={{ text: translate("readmore"), url: "/FinancialStatement" }}
    /> */}
    
    {/* <Section
    image={`url(${translate("funcMain5image")})`}
    title={translate("functionalitiesMonthlyRent")}
    reversed
    isMobile={isMobileVersion}
    height = {isMobileVersion === 1 && 85}
    renderContent={()=>{return(<>
      <div className="button-and-text">
      <p className="tab-text">{translate("functionalitiesMonthlyRentDescription")}</p>
      <div
            className="button-container"
            onClick={() => {
              localStorage.setItem("lastPositionY",window.scrollY);
              history.push("/MonthlyRent");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <p>{translate("readmore")}</p>
            <div className="green-arrow-right" />
          </div>
      </div>
      
      </>)
      
    }}
    /> */}
    
    {/* <Section
    image={`url(${translate("funcMain6image")})`}
    title={translate("functionalitiesSmartProfile")}
    invert
    reversed
    right
    isMobile={isMobileVersion}
    height = {isMobileVersion === 1 && 85}
    renderContent={()=>{return(<>
      <div className="button-and-text">
      <p className="tab-text">{translate("functionalitiesSmartProfileDescription")}</p>
      <div
            className="button-container"
            onClick={() => {
              localStorage.setItem("lastPositionY",window.scrollY);
              history.push("/SmartProfile");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <p>{translate("readmore")}</p>
            <div className="green-arrow-right" />
          </div>
      </div>
      
      </>)
      
    }}
    /> */}
    
    {/* <MainComponent
      image={`${translate("funcMain7image")}`}
      reversed
      title={translate("functionalitiesNeighbours")}
      text={[
        {
          subtitle: "",
          paragraph: translate("functionalitiesNeighboursDescription"),
        },
      ]}
      button={{ text: translate("readmore"), url: "/Neighbours" }}
    /> */}
    
    {/* <Section
    image={`url(${translate("funcMain8image")})`}
    title={translate("functionalitiesBusinessUsers")}
    invert
    reversed
    right
    height={isMobileVersion === 1 && 90}
    isMobile={isMobileVersion}
    renderContent={()=>{return(<>
      <div className="button-and-text">
      <p className="tab-text">{translate("functionalitiesBusinessUsersDescription")}</p>
      <div
            className="button-container"
            onClick={() => {
              localStorage.setItem("lastPositionY",window.scrollY);
              history.push("/BusinessUsers");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <p>{translate("readmore")}</p>
            <div className="green-arrow-right" />
          </div>
      </div>
      
      </>)
      
    }}
    /> */}
    
    {/* <PropertyComponent /> */}
    {/* <DownloadAppSection
      image={require("../../assets/images/funkc-svalete.png")}
    /> */}

      {/* new */}
      <MainComponent
        image={translate("functionality1image")}
        left
        leftLight
        title={translate("functionalitiesManagingEasily")}
        text={[
          {
            subtitle: "",
            paragraph:
            translate("functionalitiesManagingEasilyDescription"),
          },
        ]}
        button={{ text: translate("readmore"), url: "/FunctionalitiesManagement" }}
      />

      <MainComponent
        image={translate("functionality2image")}
        right
        divider
        rightDark
        halfImg
        title={translate("functionalitiesManagingShared")}
        text={[
          {
            subtitle: "",
            paragraph:
            translate("functionalitiesManagingSharedDescription"),
          },
        ]}
        button={{ text: translate("readmore"), url: "/FunctionalitiesSharedManagement" }}
      />

      <MainComponent
         image={translate("functionality3image")}
        left
        leftLight
        title={translate("functionalitiesFinances")}
        text={[
          {
            subtitle: "",
            paragraph:
            translate("functionalitiesFinancesDescription"),
          },
        ]}
        button={{ text: translate("readmore"), url: "/FunctionalitiesFinances" }}
      />

      <MainComponent
        image={translate("functionality4image")}
        right
        divider
        rightDark
        halfImg
        title={translate("functionalitiesChat")}
        text={[
          {
            subtitle: "",
            paragraph:
            translate("functionalitiesChatDescription"),
          },
        ]}
        button={{ text: translate("readmore"), url: "/FunctionalitiesChat" }}
      />

      <MainComponent
        image={translate("functionality5image")}
        left
        leftLight
        title={translate("functionalitiesUtilitiesNeighbours")}
        text={[
          {
            subtitle: "",
            paragraph:
            translate("functionalitiesUtilitiesNeighboursDescription"),
          },
        ]}
        button={{ text: translate("readmore"), url: "/FunctionalitiesNeighbours" }}
      />

      <MainComponent
        image={translate("functionality6image")}
        // opacity
        right
        divider
        rightDark
        halfImg
        title={translate("functionalitiesUtilities")}
        text={[
          {
            subtitle: "",
            paragraph:
            translate("functionalitiesUtilitiesDescription"),
          },
        ]}
        button={{ text: translate("readmore"), url: "/FunctionalitiesUtilities" }}
      />

      <MainComponent
        image={translate("functionality7image")}
        left
        leftLight
        title={translate("functionalitiesServices")}
        text={[
          {
            subtitle: "",
            paragraph:
            translate("functionalitiesServicesDescription"),
          },
        ]}
        button={{ text: translate("readmore"), url: "/FunctionalitiesServices" }}
      />

      <MainComponent
         image={translate("functionality8image")}
        // opacity
        right
        divider
        rightDark
        halfImg
        title={translate("functionalitiesSectionPhotos")}
        text={[
          {
            subtitle: "",
            paragraph:
            translate("functionalitiesSectionPhotosDescription"),
          },
        ]}
        button={{ text: translate("readmore"), url: "/FunctionalitiesDocuments" }}
      />

      <MainComponent
        image={translate("functionality9image")}
        left
        leftLight
        title={translate("functionalitiesSectionCalendar")}
        text={[
          {
            subtitle: "",
            paragraph:
            translate("functionalitiesSectionCalendarDescription"),
          },
        ]}
        button={{ text: translate("readmore"), url: "/FunctionalitiesCalendar" }}
      />

      <MainComponent
        image={translate("functionality10image")}
        // opacity
        right
        divider
        rightDark
        halfImg
        title={translate("functionalitiesSectionBusinessUsers")}
        text={[
          {
            subtitle: "",
            paragraph:
            translate("functionalitiesSectionBusinessUsersDescription"),
          },
        ]}
        button={{ text: translate("readmore"), url: "/BusinessUsers" }}
      />

      <DownloadAppSection
        // image={require("../../assets/images/download-section-background.png")}
        image="https://immotech-web.s3.eu-central-1.amazonaws.com/images/Download-section-background.png"
        title={translate("footerDownload")}
      />
    </div>
  );
}

export default Functionality;
