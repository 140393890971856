import React, { useState, useEffect } from "react";
import { TitleComponent } from "../../components";
import moment from "moment";
import "./styles.scss";
import { translate } from "../../translations/localization";
import { connect } from "react-redux";
import { sendEmail } from "../../actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactComponent({sendEmail}) {
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Моля изчакайте няколко секунди."
  );
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        let newLoadingMessage = loadingMessage + ".";
        if (newLoadingMessage === "Моля изчакайте няколко секунди.....") {
          newLoadingMessage = "Моля изчакайте няколко секунди.";
        }
        setLoadingMessage(newLoadingMessage);
      }, 500);
    }
  }, [loading, loadingMessage]);

  return (
    <div className="contact-component-container">
      <div className="left-container" />
      <div className="right-container">
        <div className="inner-container">
          <TitleComponent title={translate("howItWorksFormContactUS")} />
          <div className="flex-container">
            <div className="form-input-wrapper">
              <input
                className="form-input"
                type="text"
                value={fullName}
                placeholder={translate("howItWorksFormName")}
                onChange={(event) => setFullName(event.target.value)}
              />
            </div>
            <div className="form-input-wrapper">
              <input
                className="form-input"
                type="text"
                value={company}
                placeholder={translate("howItWorksFormCompany")}
                onChange={(event) => setCompany(event.target.value)}
              />
            </div>
          </div>
          <div className="flex-container">
            <div className="form-input-wrapper">
              <input
                className="form-input"
                type="text"
                value={email}
                placeholder={translate("howItWorksFormEmail")}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="form-input-wrapper">
              <input
                className="form-input"
                type="text"
                value={phone}
                placeholder={translate("howItWorksFormPhoneNumber")}
                onChange={(event) => setPhone(event.target.value)}
              />
            </div>
          </div>
          <div className="flex-container">
            <div className="form-textarea-wrapper">
              <textarea
                value={message}
                placeholder={translate("howItWorksFormShortDescription")}
                onChange={(event) => setMessage(event.target.value)}
              />
            </div>
          </div>
          <div className="flex-end">
            <div
              className="flex-container mobile"
              onClick={() => {
                if (!loading && email) {
                  setLoading(true);
                  setSuccess(false);
                  const payload = {
                    system: "immotech",
                    sender: fullName,
                    company: company,
                    email: email,
                    phoneNumber: phone,
                    message: message,
                    onSuccess: () => {
                      setLoading(false);
                      setSuccess(true);
                      setFullName("");
                      setCompany("");
                      setEmail("");
                      setPhone("");
                      setMessage("");
                    },
                    onError: (error) => {
                      setLoading(false);
                      toast(error);
                    },
                  };
                  sendEmail(payload);
                }
              }}
            >
              <p>{translate("howItWorksFormSendButton")}</p>
              <div className="send-icon" />
            </div>
          </div>
          {loading && <p>{loadingMessage}</p>}
          {success && <p>Вашето запитване е изпратено, благодарим Ви!</p>}
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  sendEmail: (payload) => dispatch(sendEmail(payload)),
});
export default connect(null,mapDispatchToProps)(ContactComponent);
