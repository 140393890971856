import I18n from "i18n-js";

import translationBG from "./bg.json";
import translationEN from "./en.json";
import translationDE from "./de.json";
import translationIT from "./it.json";

I18n.defaultLocale = window.localStorage.getItem("immoLanguage") || "bg";
I18n.locale = window.localStorage.getItem("immoLanguage") || "bg";

I18n.translations = {
  bg: translationBG,
  en: translationEN,
  de: translationDE,
  it: translationIT
};

export const translate = I18n.t.bind(I18n);

export const setLocale = (locale) => (I18n.locale = locale);
export const getLocale = () => I18n.currentLocale();
