import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import "./styles.scss";

function FinancialStatement() {
  const [selectedTab, setSelectedTab] = useState(
    translate("financialStatementAutomatic")
  );
  const [currentImage, setCurrentImage] = useState(
    "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-1.png"
  );

  const language = window.localStorage.getItem("immoLanguage");

  useEffect(() => {
    setSelectedTab(translate("financialStatementAutomatic"));
  }, [language]);

  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("financialStatement")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("financialStatementAutomatic")}
          </h3>
          {translate("financialStatementAutomaticDescription").map(
            (el, index) => {
              return (
                <div className="line-and-text">
                  <div className="left-line" />
                  <p>
                    {translate("financialStatementAutomaticDescription")[index]}
                  </p>
                </div>
              );
            }
          )}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-1-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-1.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("financialStatementHand")}
          </h3>
          {translate("financialStatementHandDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("financialStatementHandDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-2-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-2.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("financialStatementAutoPayment")}
          </h3>
          {translate("financialStatementAutoPaymentDescription").map(
            (el, index) => {
              return (
                <div className="line-and-text">
                  <div className="left-line" />
                  <p>
                    {
                      translate("financialStatementAutoPaymentDescription")[
                        index
                      ]
                    }
                  </p>
                </div>
              );
            }
          )}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-3-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-3.png"
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="financial-statement-container">
      <div
        className="left-container"
        style={{ backgroundImage: `url(${currentImage})` }}
      ></div>
      <div className="right-container">
        <div
          className="back-btn"
          onClick={() => history.push("/Functionality")}
        >
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <div className="inner-container">
          <TitleComponent title={translate("financialStatement")} />
          <Tabs
            wide
            tabs={[
              translate("financialStatementAutomatic"),
              translate("financialStatementHand"),
              translate("financialStatementAutoPayment"),
            ]}
            selected={selectedTab}
            onSelect={(value) => {
              setCurrentImage(null);
              setTimeout(() => {
                if (value === translate("financialStatementAutomatic")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-1-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-1.png"
                  );
                } else if (value === translate("financialStatementHand")) {
                  setCurrentImage(
                    language === "en"
                    ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-2-en.png"
                    : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-2.png"
                  );
                } else if (
                  value === translate("financialStatementAutoPayment")
                ) {
                  setCurrentImage(
                    language === "en"
                    ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-3-en.png"
                    : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-finance-3.png"
                  );
                }
              }, 10);
              setSelectedTab(value);
            }}
          />
          {selectedTab === translate("financialStatementAutomatic") ? (
            <>
              {translate("financialStatementAutomaticDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("financialStatementAutomaticDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("financialStatementHand") ? (
            <>
              {translate("financialStatementHandDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {translate("financialStatementHandDescription")[index]}
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("financialStatementAutoPayment") ? (
            <>
              {translate("financialStatementAutoPaymentDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("financialStatementAutoPaymentDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default FinancialStatement;
