import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import './styles.scss';

export default function FunctionalitiesNeighbours() {
    const language = window.localStorage.getItem("immoLanguage");

    const [selectedTab, setSelectedTab] = useState(
      translate("functionalitiesUtilitiesNeighboursTabOne")
    );
    const [currentImage, setCurrentImage] = useState(
      language === "bg"
        ? require("../../assets/images/Neighbours-participants.png")
        : translate("functionalityNeighboursImageTabOne")
    );
    // const [currentImage, setCurrentImage] = useState(
    //   language === "en"
    //     ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-1-en.png"
    //     : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-1.png"
    // );
  
    useEffect(() => {
      setSelectedTab(translate("functionalitiesUtilitiesNeighboursTabOne"));
      if (language === 'bg') require("../../assets/images/Neighbours-participants.png")
      else translate("functionalityNeighboursImageTabOne")
    }, [language]);
  
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("functionalitiesUtilitiesNeighbours")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesUtilitiesNeighboursTabOne")}
          </h3>
          {translate("functionalitiesUtilitiesNeighboursTabOneDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesUtilitiesNeighboursTabOneDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Neighbours-participants.png")
                : translate("functionalityNeighboursImageTabOne")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesUtilitiesNeighboursTabTwo")}
          </h3>
          {translate("functionalitiesUtilitiesNeighboursTabTwoDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesUtilitiesNeighboursTabTwoDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Neighbours-meetings.png")
                : translate("functionalityNeighboursImageTabTwo")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesUtilitiesNeighboursTabThree")}
          </h3>
          {translate("functionalitiesUtilitiesNeighboursTabThreeDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesUtilitiesNeighboursTabThreeDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Neighbours-voting.png")
                : translate("functionalityNeighboursImageTabThree")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesUtilitiesNeighboursTabFour")}
          </h3>
          {translate("functionalitiesUtilitiesNeighboursTabFourDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesUtilitiesNeighboursTabFourDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Neighbours-finances.png")
                : translate("functionalityNeighboursImageTabFour")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesUtilitiesNeighboursTabFive")}
          </h3>
          {translate("functionalitiesUtilitiesNeighboursTabFiveDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesUtilitiesNeighboursTabFiveDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Neighbours-board.png")
                : translate("functionalityNeighboursImageTabFive")
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="functionalities-section-screen functionalities-neighbours-container">
      <div
        className="left-container"
        style={{ backgroundImage: `url(${currentImage})` }}
      ></div>
      <div className="right-container">
        <div
          className="back-btn"
          onClick={() => history.push("/Functionality")}
        >
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <div className="inner-container">
          <TitleComponent title={translate("functionalitiesUtilitiesNeighbours")} />
          <Tabs
            wide
            tabs={[
              translate("functionalitiesUtilitiesNeighboursTabOne"),
              translate("functionalitiesUtilitiesNeighboursTabTwo"),
              translate("functionalitiesUtilitiesNeighboursTabThree"),
              translate("functionalitiesUtilitiesNeighboursTabFour"),
              translate("functionalitiesUtilitiesNeighboursTabFive"),
            ]}
            selected={selectedTab}
            onSelect={(value) => {
              // setCurrentImage(null);
              setTimeout(() => {
                if (value === translate("functionalitiesUtilitiesNeighboursTabOne")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Neighbours-participants.png")
                    : translate("functionalityNeighboursImageTabOne")
                  );
                } else if (value === translate("functionalitiesUtilitiesNeighboursTabTwo")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Neighbours-meetings.png")
                    : translate("functionalityNeighboursImageTabTwo")
                  );
                } 
                else if (value === translate("functionalitiesUtilitiesNeighboursTabThree")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Neighbours-voting.png")
                    : translate("functionalityNeighboursImageTabThree")
                  );
                }
                else if (value === translate("functionalitiesUtilitiesNeighboursTabFour")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Neighbours-finances.png")
                    : translate("functionalityNeighboursImageTabFour")
                  );
                }
                else if (value === translate("functionalitiesUtilitiesNeighboursTabFive")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Neighbours-board.png")
                    : translate("functionalityNeighboursImageTabFive")
                  );
                }
              }, 10);
              setSelectedTab(value);
            }}
          />
          {selectedTab === translate("functionalitiesUtilitiesNeighboursTabOne") ? (
            <>
              {translate("functionalitiesUtilitiesNeighboursTabOneDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("functionalitiesUtilitiesNeighboursTabOneDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("functionalitiesUtilitiesNeighboursTabTwo") ? (
            <>
              {translate("functionalitiesUtilitiesNeighboursTabTwoDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {translate("functionalitiesUtilitiesNeighboursTabTwoDescription")[index]}
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesUtilitiesNeighboursTabThree") ? (
            <>
              {translate("functionalitiesUtilitiesNeighboursTabThreeDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesUtilitiesNeighboursTabThreeDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesUtilitiesNeighboursTabFour") ? (
            <>
              {translate("functionalitiesUtilitiesNeighboursTabFourDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesUtilitiesNeighboursTabFourDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesUtilitiesNeighboursTabFive") ? (
            <>
              {translate("functionalitiesUtilitiesNeighboursTabFiveDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesUtilitiesNeighboursTabFiveDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
