import React from "react";
import { AppStore, GooglePlay } from "../../components";
import "./styles.scss";

function DownloadAppSection({ image, title }) {
  return (
    <div
      className="download-app-container"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="download-app-inner-container">
        {title && <h1>{title}</h1>}
        <div className="flex-container">
          <AppStore white big />
          <div className="app-picture" />
          <GooglePlay white big />
        </div>
      </div>
    </div>
  );
}

export default DownloadAppSection;
