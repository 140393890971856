import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import "./styles.scss";

function UtilityBills() {
  const language = window.localStorage.getItem("immoLanguage");

  const [selectedTab, setSelectedTab] = useState(
    translate("utilityBillsAllBills")
  );
  const [currentImage, setCurrentImage] = useState(
    language === "en"
      ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-1-en.png"
      : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-1.png"
  );

  useEffect(() => {
    setSelectedTab(translate("utilityBillsAllBills"));
  }, [language]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("utilityBills")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("utilityBillsAllBills")}
          </h3>
          {translate("utilityBillsAllBillsDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("utilityBillsAllBillsDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-1-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-1.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("utilityBillsAutomatic")}
          </h3>
          {translate("utilityBillsAutomaticDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("utilityBillsAutomaticDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-2-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-2.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("utilityBillsTransparency")}
          </h3>
          {translate("utilityBillsTransparencyDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("utilityBillsTransparencyDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-3-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-3.png"
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="functionalities-section-screen utility-bills-container">
      <div
        className="left-container"
        style={{ backgroundImage: `url(${currentImage})` }}
      ></div>
      <div className="right-container">
        <div
          className="back-btn"
          onClick={() => history.push("/Functionality")}
        >
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <div className="inner-container">
          <TitleComponent title={translate("utilityBills")} />
          <Tabs
            wide
            tabs={[
              translate("utilityBillsAllBills"),
              translate("utilityBillsAutomatic"),
              translate("utilityBillsTransparency"),
            ]}
            selected={selectedTab}
            onSelect={(value) => {
              setCurrentImage(null);
              setTimeout(() => {
                if (value === translate("utilityBillsAllBills")) {
                  setCurrentImage(
                    language === "en"
                    ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-1-en.png"
                    : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-1.png"
                  );
                } else if (value === translate("utilityBillsAutomatic")) {
                  setCurrentImage(
                    language === "en"
                    ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-2-en.png"
                    : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-2.png"
                  );
                } else if (value === translate("utilityBillsTransparency")) {
                  setCurrentImage(
                    language === "en"
                    ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-3-en.png"
                    : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-3.png"
                  );
                }
              }, 10);
              setSelectedTab(value);
            }}
          />
          {selectedTab === translate("utilityBillsAllBills") ? (
            <>
              {translate("utilityBillsAllBillsDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("utilityBillsAllBillsDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("utilityBillsAutomatic") ? (
            <>
              {translate("utilityBillsAutomaticDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {translate("utilityBillsAutomaticDescription")[index]}
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("utilityBillsTransparency") ? (
            <>
              {translate("utilityBillsTransparencyDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("utilityBillsTransparencyDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default UtilityBills;
