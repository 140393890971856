import React, { useState } from "react";
import "./styles.scss";

function CategoryComponent({ categoryData, categoryName }) {
  const [opened, setOpened] = useState(false);
  
  return (
    <div className="category-component-container">
      <div
        className="flex-container"
        onClick={() => setOpened((prev) => !prev)}
      >
        <h3>{categoryName}</h3>
        <div className={`arrow ${opened && "opened"}`} />
      </div>
      {opened && (
        <div className="answers-container">
          {categoryData.map((el) => {
            return (
              <div className="answer">
                <h4>{el.question}</h4>
                <p>{el.answer}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default CategoryComponent;
