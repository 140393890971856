import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import { setLocale, translate } from "../../translations/localization";
import { setLanguageLocale } from "../../actions";
import { useDispatch } from "react-redux";
import "./styles.scss";

function Header() {
  const [language, setLanguage] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const languageCode = localStorage.getItem("immoLanguage");
  const location = useLocation();
  const dispatch = useDispatch();

  const changeLanguage = (selected) => {
    setLanguage(selected);
    setLocale(selected);
    dispatch(setLanguageLocale(selected));
    localStorage.setItem("immoLanguage", selected);
    if (location.pathname === "/AboutUs") window.location.reload(true);
    if (location.pathname === "/FAQ") window.location.reload(true);
    history.push(location.pathname + location.search);
    setDropdown(false);
  };

  useEffect(() => {
    if (!languageCode) {
      localStorage.setItem("immoLanguage", "bg");
      setLanguage("bg");
      setLocale("bg");
    } else setLanguage(languageCode);
  }, [languageCode]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch("http://ip-api.com/json");
        const data = await response.json();
        switch (data?.countryCode) {
          case "CH":
          case "DE":
          case "AT":
            changeLanguage("de");
            return;
          case "IT":
            changeLanguage("it");
            return;
          case "BG":
            changeLanguage("bg");
            return;
          default:
            changeLanguage("en");
            return;
        }
      } catch (error) {
        console.error("Error fetching country information:", error);
      }
    };

    fetchCountry();
  }, []);

  return (
    <div className="header-container">
      <div
        className={`header-element ${location.pathname === "/Functionality" && "selected"}`}
        onClick={() => {
          localStorage.setItem("lastPositionY", "0");
          history.push("/Functionality");
        }}
      >
        {translate("navMenuFunctionalities")}
      </div>
      <div className={`header-element ${location.pathname === "/Subscription" && "selected"}`} onClick={() => history.push("/Subscription")}>
        {translate("navMenuSubscription")}
      </div>
      <div className="logo" onClick={() => history.push("/")} />
      <div className={`header-element ${location.pathname === "/AboutUs" && "selected"}`} onClick={() => history.push("/AboutUs")}>
        {translate("navMenuAboutUs")}
      </div>
      <div className={`header-element last ${(location.pathname === "/Blog" || location.pathname.includes("SingleBlog")) && "selected"}`} onClick={() => history.push("/Blog")}>
        {translate("navMenuBlog")}
      </div>

      <div className={`languages-container ${dropdown ? "opened" : "closed"}`} onClick={() => setDropdown(!dropdown)}>
        <div className="language selected">{language === "bg" ? "BG" : language === "en" ? "EN" : language === "de" ? "DE" : "IT"}</div>
        <img src={require("../../assets/icons/arrow-down-black.svg")} alt="dropdown arrow" className="arrow" />
      </div>

      <div onClick={() => setDropdown(false)} onMouseLeave={() => setDropdown(false)} className={`languages-dropdown ${dropdown ? "open" : ""}`}>
        <h3 className="language-option" onClick={() => changeLanguage("bg")}>
          BG
        </h3>
        <h3 className="language-option" onClick={() => changeLanguage("en")}>
          EN
        </h3>
        <h3 className="language-option" onClick={() => changeLanguage("de")}>
          DE
        </h3>
        <h3 className="language-option" onClick={() => changeLanguage("it")}>
          IT
        </h3>
      </div>
    </div>
  );
}

export default withRouter(Header);
