import React, { useState } from "react";
import { translate } from "../../translations/localization";
import { Section, TitleComponent } from "..";
import "./styles.scss";
import { Icon } from 'react-icons-kit'
import {plus} from 'react-icons-kit/icomoon/plus';
import {minus} from 'react-icons-kit/icomoon/minus'

const pictureMap = {
  0: require("../../assets/images/functionalities-section-8-1.png"),
  1: require("../../assets/images/functionalities-section-8-2.png"),
  2: require("../../assets/images/functionalities-section-8-3.png"),
  3: require("../../assets/images/functionalities-section-8-4.png"),
};

const pictureMapEn = {
  0:"https://immotech-web.s3.eu-central-1.amazonaws.com/images/funcMain/functionalities-section-9-en.png",
  1:"https://immotech-web.s3.eu-central-1.amazonaws.com/images/funcMain/functionalities-section-9-1-en.png",
  2:"https://immotech-web.s3.eu-central-1.amazonaws.com/images/funcMain/functionalities-section-9-2-en.png",
  3:require("../../assets/images/functionalities-section-8-4.png")
}

function PropertyComponent() {
  const [selected, setSelected] = useState(0);

  const language = window.localStorage.getItem("immoLanguage");

  return (
    <div
      className="property-component-container"
    >
      {window.innerWidth < 900 ? (
        <div style={{backgroundColor: "#070E22"}}>
        <TitleComponent title={translate("functionalitiesMoreFunctionalities")} ml={4} color={"#FFF"} mt={4}/>
        {Object.keys(pictureMap).map((key, index) => {
            return (
              <>
                <div
                  className="image"
                  style={{ backgroundImage: `url(${language === "en" ? pictureMapEn[key] : pictureMap[key]})` }}
                />
                <div className="text-container">
                  <h3>{translate(`functionalitiesMoreFunctionalities${index+1}`)}</h3>
                  <p>{translate(`functionalitiesMoreFunctionalities${index+1}Description`)}</p>
                </div>
              </>
            );
          })}
        </div>
      ) : (
        <Section 
        image={`url(${language === "en" ? pictureMapEn[selected] : pictureMap[selected]})`}
        reversed
        right
        isMobile={window.innerWidth < 900 && 1}
        renderContent={()=>{
          return(<>
          <div className="overlay-container">
          <TitleComponent title={translate("functionalitiesMoreFunctionalities")}/>
          <div className="wrapper">
            <div
              className={`flex-container ${selected === 0 && "selected"}`}
              onClick={() => setSelected(0)}
            >
              <h1>{translate("functionalitiesMoreFunctionalities1")}</h1>
              <div className="arrow-container">
                {selected === 0 ? <Icon icon={minus} style={{color:"#00D297"}}/> : <Icon icon={plus} style={{color:"#00D297"}}/>  }
              </div>
            </div>
            <p className={`text-info ${selected === 0 && "selected"}`}>
              {translate("functionalitiesMoreFunctionalities1Description")}
            </p>
          </div>
          <div className="wrapper">
            <div
              className={`flex-container ${selected === 1 && "selected"}`}
              onClick={() => setSelected(1)}
            >
              <h1>{translate("functionalitiesMoreFunctionalities2")}</h1>
              <div className="arrow-container">
                {selected === 1 ? <Icon icon={minus} style={{color:"#00D297"}}/> : <Icon icon={plus} style={{color:"#00D297"}}/> }
              </div>
            </div>
            <p className={`text-info ${selected === 1 && "selected"}`}>
              {translate("functionalitiesMoreFunctionalities2Description")}
            </p>
          </div>
          <div className="wrapper">
            <div
              className={`flex-container ${selected === 2 && "selected"}`}
              onClick={() => setSelected(2)}
            >
              <h1>{translate("functionalitiesMoreFunctionalities3")}</h1>
              <div className="arrow-container">
                {selected === 2 ? <Icon icon={minus} style={{color:"#00D297"}}/> : <Icon icon={plus} style={{color:"#00D297"}}/>  }
              </div>
            </div>
            <p className={`text-info ${selected === 2 && "selected"}`}>
            {translate("functionalitiesMoreFunctionalities3Description")}
            </p>
          </div>
          <div className="wrapper">
            <div
              className={`flex-container ${selected === 3 && "selected"}`}
              onClick={() => setSelected(3)}
            >
              <h1>{translate("functionalitiesMoreFunctionalities4")}</h1>
              <div className="arrow-container">
                {selected === 3 ? <Icon icon={minus} style={{color:"#00D297"}}/> : <Icon icon={plus} style={{color:"#00D297"}}/>  }
              </div>
            </div>
            <p className={`text-info ${selected === 3 && "selected"}`}>
            {translate("functionalitiesMoreFunctionalities4Description")}
            </p>
          </div>
        </div>
        </>)
        }}
        />
      )}
    </div>
  );
}

export default PropertyComponent;
