import React from "react";
import { AppStore, GooglePlay, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import "./styles.scss";

function MainComponent({
  title,
  text,
  ads,
  left,
  opacity,
  image,
  reversed,
  smallPic,
  video,
  mobileSpecial,
  button,
  LeftLine,
  mobileRight,
  mobileLeft,
  halfImg,
  subtitleMargin,
  darkBackground,
  titleUppercase,
  leftLight,
  rightDark,
  darkMobile,
  hideMobile,
  fullWidth,
  divider,
  paragraph
}) {
  const language = window.localStorage.getItem("immoLanguage");
  
  return (
    <div
      className={`main-component-container ${reversed && "reversed"} ${(divider && window.innerWidth < 900) && "divider"} ${hideMobile && "hide-mobile"} ${
        !opacity && "right"
      } ${smallPic && "small-pic"} ${rightDark && "right-dark"} ${darkBackground && "dark-background"} ${halfImg && "half-img"} ${fullWidth && "full-width"}`}
      style={{ backgroundImage: `url(${image})` }}
    >
      {video && (
        <div className="video-container">
          <video
            playsInline={true}
            muted={true}
            preload="auto"
            loop={true}
            autoPlay={true}
          >
            <source src={"/videos/abonament-video-2.mp4"} type="video/mp4" />
          </video>
        </div>
      )}
      <div
        className={`overlay-container ${left && "left"} ${darkMobile && "dark-mobile"} ${mobileLeft && "mobile-left"} ${
          opacity && "opacity" 
        } ${leftLight && "left-light"} ${rightDark && "right-dark"} ${darkBackground && "dark-background"}`}
      >
        {!paragraph &&
        <div className="text-inner-container">
          <TitleComponent title={title} titleUppercase={titleUppercase ? titleUppercase : ''} mobileSpecial={mobileSpecial} />
          {text?.map((el) => {
            return (
          <div className="text-container">
            <h3 className={`${left ? "white" : "black"} ${el?.subtitle ? "regular" : "medium"} ${el?.medium ? 'medium' : ''} ${subtitleMargin ? 'subtitle-margin' : ''}`}>{el?.subtitle}</h3>
            {LeftLine === 1 ? el.paragraph === undefined ?  "" :
              <div className="line-and-text">
                  <div className="left-line"/>
                  <p className="tab-text">{el.paragraph}</p>
              </div> 
            :<p>{el.paragraph}</p>
            }
          </div>
            );
          })}
          {ads && (
            <div className="additional-elements-container">
              <AppStore coloured big />
              <GooglePlay coloured big />
            </div>
          )}
          {button && (
            <div
              className="button-container"
              onClick={() => {

                if(!button?.newWindowUrl) {
                  localStorage.setItem("lastPositionY",window.scrollY);
                  history.push(button.url);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
                else {
                  window.open(button.url)
                }
              }}
            >
              <p className="uppercase">{button.text}</p>
              <div className="green-arrow-right" />
            </div>
          )}
        </div>}

        {paragraph &&
        <div className="text-inner-container">
        <TitleComponent title={title} titleUppercase={titleUppercase ? titleUppercase : ''} mobileSpecial={mobileSpecial} />
        <div className="text-container">
          <p className="tab-text">{paragraph || ""}</p>
        </div>
        {button && (
          <div
            className="button-container"
            onClick={() => {

              if(!button?.newWindowUrl) {
                localStorage.setItem("lastPositionY",window.scrollY);
                history.push(button.url);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }
              else {
                window.open(button.url)
              }
            }}
          >
            <p className="uppercase">{button.text}</p>
            <div className="green-arrow-right" />
          </div>
        )}
      </div>}
      </div>
      {image && window.innerWidth < 900 && (
        <div
          className={`main-text-image ${mobileRight && "mobileRight"} ${mobileLeft && "mobileLeft"}`}
          style={{ backgroundImage: `url(${image})` }}
        />
      )}
    </div>
  );
}

export default MainComponent;
