import React from "react";
import { QuestionsComponent, ContactComponent } from "../../components";
import "./styles.scss";

function Functionality() {
  return (
    <div className="functionality-container">
      <QuestionsComponent />
      <ContactComponent />
    </div>
  );
}

export default Functionality;
