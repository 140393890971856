import React from "react";
import { TitleComponent } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

function GridHomeComponent() {
  return (
    <div className="grid-home-container">
      <div className="left-container" style={{ backgroundImage: `url(${translate("homepageGridImage")})`}}>
        {/* <div className="flex-column">
          <div className="flex-container top">
            <div className="grid-image one" />
            <div className="grid-image two" />
            <div className="grid-image three" />
          </div>
          <div className="flex-container">
            <div className="grid-image four" />
            <div className="grid-image five" />
            <div className="grid-image six" />
          </div>
          <div className="flex-container bottom">
            <div className="grid-image seven" />
            <div className="grid-image eight" />
            <div className="grid-image nine" />
          </div>
        </div> */}
      </div>
      <div className="right-container">
        <div className="text-inner-container">
          <TitleComponent title={translate("homepageFollowUs")} />
          <h3>{translate("homepageDontMiss")}</h3>
          <div className="social-buttons-container">
            <div
              className="flex-container margin"
              onClick={() =>
                window.open("https://www.instagram.com/immotech2020/")
              }
            >
              <p>{translate("homepageFollowUsInstagram")}</p>
              <div className="green-arrow" />
            </div>
            <div
              className="flex-container margin"
              onClick={() =>
                window.open("https://www.facebook.com/immotechapp/")
              }
            >
              <p>{translate("homepageLearnMoreFacebook")}</p>
              <div className="green-arrow" />
            </div>
            <div
              className="flex-container margin"
              onClick={() =>
                window.open("https://www.youtube.com/@immotech5709/videos")
              }
            >
              <p>{translate("homepageLearnMoreYouTube")}</p>
              <div className="green-arrow" />
            </div>
             <div className="flex-container margin" onClick={() =>
              window.open("https://www.linkedin.com/company/immotech-app/")
            }>
              <p>{translate("homepageLearnMoreLinkedIn")}</p>
              <div className="green-arrow" />
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default GridHomeComponent;
