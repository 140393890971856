import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import './styles.scss';

export default function FunctionalitiesFinances() {
    const language = window.localStorage.getItem("immoLanguage");

    const [selectedTab, setSelectedTab] = useState(
      translate("functionalitiesFinancesTabOne")
    );
    const [currentImage, setCurrentImage] = useState(
      language === "bg"
        ? require("../../assets/images/Finances-total.png")
        : translate("functionalityFinancesImageTabOne")
    );
    const [prevImage, setPrevImage] = useState(require("../../assets/images/Finances-total.png"))
  
    useEffect(() => {
      setSelectedTab(translate("functionalitiesFinancesTabOne"));
      if (language === 'bg') setCurrentImage(require("../../assets/images/Finances-total.png"))
      else translate("functionalityFinancesImageTabOne")
    }, [language]);
  
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("functionalitiesFinances")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesFinancesTabOne")}
          </h3>
          {translate("functionalitiesFinancesTabOneDescription")?.map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesFinancesTabOneDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Finances-total.png")
                : translate("functionalityFinancesImageTabOne")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesFinancesTabTwo")}
          </h3>
          {translate("functionalitiesFinancesTabTwoDescription")?.map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesFinancesTabTwoDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? require("../../assets/images/Finances-income.png")
                : translate("functionalityFinancesImageTabTwo")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesFinancesTabThree")}
          </h3>
          {translate("functionalitiesFinancesTabThreeDescription")?.map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesFinancesTabThreeDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Finances-expenses.png")
                : translate("functionalityFinancesImageTabThree")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesFinancesTabFour")}
          </h3>
          {translate("functionalitiesFinancesTabFourDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesFinancesTabFourDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Finances-bank.png")
                : translate("functionalityFinancesImageTabFour")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesFinancesTabFive")}
          </h3>
          {translate("functionalitiesFinancesTabFiveDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesFinancesTabFiveDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? require("../../assets/images/Finances-shared.png")
                : translate("functionalityFinancesImageTabFive")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesFinancesTabSix")}
          </h3>
          {translate("functionalitiesFinancesTabSixDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesFinancesTabSixDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Finances-statistics.png")
                : translate("functionalityFinancesImageTabSix")
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="functionalities-section-screen functionalities-my-finances-container">
      <div
        className="left-container"
        // style={{ backgroundImage: `url(${currentImage ? currentImage : prevImage})` }}
        style={{ backgroundImage: `url(${currentImage})` }}
      ></div>
      <div className="right-container">
        <div
          className="back-btn"
          onClick={() => history.push("/Functionality")}
        >
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <div className="inner-container">
          <TitleComponent title={translate("functionalitiesFinances")} />
          <Tabs
            wide
            tabs={[
              translate("functionalitiesFinancesTabOne"),
              translate("functionalitiesFinancesTabTwo"),
              translate("functionalitiesFinancesTabThree"),
              translate("functionalitiesFinancesTabFour"),
              translate("functionalitiesFinancesTabFive"),
              translate("functionalitiesFinancesTabSix"),
            ]}
            selected={selectedTab}
            onSelect={(value) => {
              setCurrentImage(null);
              setTimeout(() => {
                if (value === translate("functionalitiesFinancesTabOne")) {
                  // setCurrentImage(
                  //   language === "en"
                  //   ? require("../../assets/images/Finances-total.png")
                  //   : require("../../assets/images/Finances-total.png")
                  // );
                  if (language === 'bg') setCurrentImage(require("../../assets/images/Finances-total.png"))
                  else setCurrentImage(translate("functionalityFinancesImageTabOne"))
                } else if (value === translate("functionalitiesFinancesTabTwo")) {
                  // setCurrentImage(
                  //   language === "en"
                  //   ? require("../../assets/images/Finances-income.png")
                  //   : require("../../assets/images/Finances-income.png")
                  // );
                  if (language === 'bg') setCurrentImage(require("../../assets/images/Finances-income.png"))
                  else setCurrentImage(translate("functionalityFinancesImageTabTwo"))
                } else if (value === translate("functionalitiesFinancesTabThree")) {
                  // setCurrentImage(
                  //   language === "en"
                  //   ? require("../../assets/images/Finances-expenses.png")
                  //   : require("../../assets/images/Finances-expenses.png")
                  // );
                  if (language === 'bg') setCurrentImage(require("../../assets/images/Finances-expenses.png"))
                  else setCurrentImage(translate("functionalityFinancesImageTabThree"))
                } else if (value === translate("functionalitiesFinancesTabFour")) {
                  // setCurrentImage(
                  //   language === "en"
                  //   ? require("../../assets/images/Finances-bank.png")
                  //   : require("../../assets/images/Finances-bank.png")
                  // );
                  if (language === 'bg') setCurrentImage(require("../../assets/images/Finances-bank.png"))
                  else setCurrentImage(translate("functionalityFinancesImageTabFour"))
                } else if (value === translate("functionalitiesFinancesTabFive")) {
                  // setCurrentImage(
                  //   language === "en"
                  //   ? require("../../assets/images/Finances-shared.png")
                  //   : require("../../assets/images/Finances-shared.png")
                  // );
                  if (language === 'bg') setCurrentImage(require("../../assets/images/Finances-shared.png"))
                  else setCurrentImage(translate("functionalityFinancesImageTabFive"))
                } if (value === translate("functionalitiesFinancesTabSix")) {
                  // setCurrentImage(
                  //   language === "en"
                  //   ? require("../../assets/images/Finances-statistics.png")
                  //   : require("../../assets/images/Finances-statistics.png")
                  // );
                  if (language === 'bg') setCurrentImage(require("../../assets/images/Finances-statistics.png"))
                  else setCurrentImage(translate("functionalityFinancesImageTabSix"))
                }
              }, 10);
              setSelectedTab(value);
            }}
          />
          {selectedTab === translate("functionalitiesFinancesTabOne") ? (
            <>
              {translate("functionalitiesFinancesTabOneDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("functionalitiesFinancesTabOneDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("functionalitiesFinancesTabTwo") ? (
            <>
              {translate("functionalitiesFinancesTabTwoDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {translate("functionalitiesFinancesTabTwoDescription")[index]}
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesFinancesTabThree") ? (
            <>
              {translate("functionalitiesFinancesTabThreeDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesFinancesTabThreeDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesFinancesTabFour") ? (
            <>
              {translate("functionalitiesFinancesTabFourDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesFinancesTabFourDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesFinancesTabFive") ? (
            <>
              {translate("functionalitiesFinancesTabFiveDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesFinancesTabFiveDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesFinancesTabSix") ? (
            <>
              {translate("functionalitiesFinancesTabSixDescription")?.map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesFinancesTabSixDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          )
           : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
