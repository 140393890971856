import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Route, Switch } from "react-router";
import { Header, Footer, MobileHeader } from "../../components";
import {
  Home,
  Functionality,
  FAQ,
  Subscription,
  AboutUs,
  SingleBlog,
  SmartProfile,
  UtilityBills,
  FinancialStatement,
  MonthlyRent,
  BusinessUsers,
  Neighbours,
  Blog,
  FunctionalitiesManagement,
  FunctionalitiesSharedManagement,
  FunctionalitiesFinances,
  FunctionalitiesUtilities,
  FunctionalitiesServices,
  FunctionalitiesCalendar,
  FunctionalitiesBusinessUsers,
  FunctionalitiesDocuments,
  FunctionalitiesNeighbours,
  FunctionalitiesChat,
} from "../../screens";
import { redirectPath } from "../../utilities/helpers";

export const Routes = () => {
  return (
    <div className="dashboard-wrap">
      {window.innerWidth < 900 ? <MobileHeader /> : <Header />}
      <TransitionGroup>
        <CSSTransition classNames="fade" enter leave timeout={1000}>
          <Switch>
            <Route exact path={redirectPath("/")} component={Home} />
            <Route exact path="/Functionality" component={Functionality} />
            <Route exact path="/FAQ" component={FAQ} />
            <Route exact path="/Subscription" component={Subscription} />
            <Route exact path="/AboutUs" component={AboutUs} />
            <Route path="/SingleBlog" component={SingleBlog} />
            <Route path="/SingleBlog/:id" component={SingleBlog} />
            <Route exact path="/SmartProfile" component={SmartProfile} />
            <Route exact path="/UtilityBills" component={UtilityBills} />
            <Route exact path="/FinancialStatement" component={FinancialStatement} />
            <Route exact path="/BusinessUsers" component={BusinessUsers} />
            <Route exact path="/MonthlyRent" component={MonthlyRent} />
            <Route exact path="/Neighbours" component={Neighbours} />
            <Route exact path="/Blog" component={Blog} />
            <Route exact path="/FunctionalitiesManagement" component={FunctionalitiesManagement} />
            <Route exact path="/FunctionalitiesSharedManagement" component={FunctionalitiesSharedManagement} />
            <Route exact path="/FunctionalitiesFinances" component={FunctionalitiesFinances} />
            <Route exact path="/FunctionalitiesUtilities" component={FunctionalitiesUtilities} />
            <Route exact path="/FunctionalitiesServices" component={FunctionalitiesServices} />
            <Route exact path="/FunctionalitiesCalendar" component={FunctionalitiesCalendar} />
            <Route exact path="/FunctionalitiesBusinessUsers" component={FunctionalitiesBusinessUsers} />
            <Route exact path="/FunctionalitiesDocuments" component={FunctionalitiesDocuments} />
            <Route exact path="/FunctionalitiesNeighbours" component={FunctionalitiesNeighbours} />
            <Route exact path="/FunctionalitiesChat" component={FunctionalitiesChat} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </div>
  );
};
