import React from 'react';
import './styles.scss';
import { MainComponent, BlogCard, BlogComponent, Loading } from '../../components';
import { translate } from "../../translations/localization";
import Scrollbars from "react-custom-scrollbars";
import { getArticles } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';

export default function Blog() {
  const blogData = [
    { title: translate("blogTitle"), url: '/SingleBlog' },
    // { title: translate("blogTitle"), url: '/SingleBlog' },
  ];
  const articlesRef = useRef();

  const dispatch = useDispatch();
  const articles = useSelector(state => state.blog.articles)
  const loading = useSelector(state => state.starter.loading)
  const language = window.localStorage.getItem("immoLanguage");
  const [curPage, setCurPage] = useState(2);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0

    if (t >= 0.97 && articles.totalPages >= curPage) {
      // articlesRef.current.scrollTop(30);
      dispatch(getArticles({ page: curPage, limit: 20 }));
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    dispatch(getArticles({ page: 1, limit: 20 }))
  }, []);

  return (
    <div className='blog-container'>
        {/* map server data - index === 0 -> full width */}
        <MainComponent
        image={require("../../assets/images/Immotech-Article-Photo.png")}
        // opacity
        darkBackground
        fullWidth
        darkMobile
        hideMobile
        right
        title={translate("aboutUsNinePercent")}
        titleUppercase
        text={[
          { paragraph: translate("aboutUsNinePercentShortDescription") },
        ]}
        button={{ text: translate("readmore"), url: "/SingleBlog" }}
      />

      {/* {!articles?.docs?.length && <p className='loading'>Loading...</p>} */}

      {/* {articles?.docs?.length &&
      <MainComponent
        image={articles?.docs[0] ? articles?.docs[0]?.coverPhoto : ""}
        // opacity
        darkBackground
        fullWidth
        darkMobile
        hideMobile
        right
        title={articles?.docs[0] ? articles?.docs[0]?.title[language] : ""}
        titleUppercase
        paragraph={articles?.docs[0] ? articles?.docs[0]?.text[language] : ""}
        button={{ text: translate("readmore"), url: articles?.docs[0] ? `/SingleBlog/${articles?.docs[0]?._id}` : '/Blog' }}
      />} */}

    {(window.innerWidth < 900 && articles?.docs?.length >= 1) ?
      <div className='blogs-container'>
       {/* {blogData?.map((blog, index) => {
        //  return (
        //   <BlogCard
        //     image="https://immotech-web.s3.eu-central-1.amazonaws.com/images/Homepage-Section-2.png"
        //     title={blog?.title}
        //     button={{ text: translate("readmore"), url: blog?.url }}
        //   />
        //     )
        //   })} */}

       {/* {articles?.docs?.map((blog, index) => {
         return (
          <BlogCard
            image={blog?.coverPhoto || ""}
            title={blog?.title ? blog?.title[language] : ""}
            button={{ text: translate("readmore"), url: blog?._id ? `/SingleBlog/${blog?._id}` : '/Blog' }}
          />
            )
          })} */}
      </div> :

      <></>

      // <Scrollbars
      //   style={{ height: "92%", width: "100%", marginTop: "1%" }}
      //   onUpdate={handleUpdate}
      //   ref={articlesRef}
      //   autoHeight
      //   autoHeightMin={800}
      //   autoHeightMax={800}
      //   renderView={(props) => (
      //     <div {...props} style={{ ...props.style, overflowX: "hidden", position: "relative" }} className="view" />
      //   )}
      //   id={"scrollbar"}
      //   >

      //       <div className='blogs-container'>
      //         {/* {blogData?.slice(1)?.map((blog, index) => {
      //           return (
      //            <BlogCard
      //              image="https://immotech-web.s3.eu-central-1.amazonaws.com/images/Homepage-Section-2.png"
      //              title={blog?.title}
      //              button={{ text: translate("readmore"), url: blog?.url }}
      //            />
      //              )
      //            })} */}

      //         {articles?.docs?.slice(1)?.map(article => {
      //           return (
      //            <BlogCard
      //              image={article?.coverPhoto || ""}
      //              title={article?.title ? article?.title[language] : ""}
      //              button={{ text: translate("readmore"), url: article?._id ? `/SingleBlog/${article?._id}` : '/Blog' }}
      //            />
      //              )
      //            })}
      //       </div>
      //   </Scrollbars>

            // <div className='blogs-container' 
            // ref={articlesRef} 
            // onScroll={delayedScrolling}
            // >
            //   {articles?.docs?.slice(1)?.map(article => {
            //     return (
            //      <BlogCard
            //        image={article?.coverPhoto || ""}
            //        title={article?.title ? article?.title[language] : ""}
            //        button={{ text: translate("readmore"), url: article?._id ? `/SingleBlog/${article?._id}` : '/Blog' }}
            //      />
            //        )
            //      })}
            // </div>
        }

      {/* {loading && <Loading />} */}
    </div>
  )
}
