import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import { useSelector, useDispatch } from "react-redux";
import { getArticle } from "../../actions";
import { useParams } from 'react-router-dom';
import "./styles.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

function BlogComponent({ currentBlog }) {

  const { id } = useParams();
  // const image = useLocation().state;

  const dispatch = useDispatch();
  const article = useSelector(state => state.blog.article)
  const language = window.localStorage.getItem("immoLanguage");
  const data = { 
    title: '9% от жилищата у нас са отдадени под наем', 
    text: [
      'Общо 9% от жилищата у нас са отдадени под наем, сочи проучване на виенския Институт за недвижими имоти, строителство и жилища, цитирано от проф. Волфганг Аман по време на конференцията за недвижими имоти BalRec, която се провежда днес.',
      'Този дял е сравнително нисък за Европа, като средното за 25-те стари членки в ЕС е 24%.',
      'Впечатление прави, че по-развитите икономики като Швейцария, Германия и Холандия имат значително по-висок дял. Швейцария например има дял от 69% от общия жилищен фонд, или най-висок сред пазарите, включени в проучването. Тя е следвана от Германия с 52%, Холандия с 43% и Австрия с 41%.',
      'Липсата на повече и разнообразни възможности за обитаване от една страна ограничава самите потребителите, но от друга е неефективно за икономиката, смята Аман. Той е на мнение, че, в зависимост от развитието на даден пазар, ако потребителите не са в състояние да закупят, те трябва да имат лесен достъп до жилища под наем, а не да бъдат принудени да живеят с родителите си.',
      'За сравнение, в Румъния и Черна гора делът на наетите жилища е едва 2%. В Полша той е 4%, в Унгария – 7%, а в Словения – 8%.',
      'Виенският институт е започнал проект за развитието на социални жилищни схеми, които да бъдат отдавани под наем на достъпни нива в Черна Гора, където с подкрепата на правителството досега са били изградени около 3 хил. имота за отдаване под наем.',
      'По думите му социалните жилища са несправедливо на заден план. Те могат да спомогнат за стабилизацията на пазара в ценово отношение, но също и като строителна активност'
    ],
    image: '../../assets/images/homepage-section-1.png'
   }

  useEffect(() => {
    if (id) dispatch(getArticle({ articleId: id }))
  }, [id]);

  return (
    <div className="blog-component-container">
      {/* old */}
      {/* <div className="left-container">
        <div className="back-btn-container" onClick={() => history.push("/AboutUs")}>
          <div className="arrow-left" />
          <p>Назад</p>
        </div>
        <h1>{translate(`${id}name`)}</h1>
        <div className="scroll-container">
          {translate(`${id}text`).map(line => {
            return (<p>{line}</p>)
          })}
          <p id="source">{translate("aboutUsSource")} </p>
          <p id="link" onClick={() => window.open(translate(`${id}source`))}>
            {translate(`${id}source`)}
          </p>
        </div>
      </div> */}


      <div className="left-container">
        <div className="back-btn-container" onClick={() => history.push("/Blog")}>
          <div className="arrow-left" />
          <p>Назад</p>
        </div>
        <h1 className="uppercase">{data?.title || ''}</h1>
        {/* <h1 className="uppercase">{article?.title ? article?.title[language] : ''}</h1> */}
        <div className="scroll-container">
          {data?.text?.map((line, index) => {
            return <p key={`line-blog-article-${index}`}>{line || ''}</p>
          })}

          {/* {article?.text && article?.text[language] && 
          <p
          className="article-text"
          style={{ height: "100%" }}
            dangerouslySetInnerHTML={{ __html: article?.text[language] || "" }}
          ></p>} */}

          {/* old */}
          {/* {article?.text && article?.text[language] && article?.text[language]?.map((line, index) => {
            return <p key={`line-blog-article-${index}`}>{line || ''}</p>
          })} */}
        </div>
      </div>

      {/* old */}
      {/* <div
        className="right-container"
        style={{ backgroundImage: `url(${translate(`${id}image`)})` }}
      /> */}

      {/* <LazyLoadImage
       effect="blur"
       src={article?.coverPhoto}
       wrapperClassName="right-container"
      >
      </LazyLoadImage> */}

      {/* {article?.coverPhoto && <div
        className="right-container"
        style={{ backgroundImage: `url(${article?.coverPhoto})` }}
      />} */}
      
      {data?.image && <div
        className="right-container"
        style={{ backgroundImage: `url(${require("../../assets/images/Immotech-Article-Photo.png")})` }}
      />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentBlog: state.blog.article,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BlogComponent);
