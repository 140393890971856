import React from "react";
import { translate } from "../../translations/localization";
import "./styles.scss";

function GooglePlay({ coloured, big, medium, white }) {
  return (
    <div
      className={`google-store-container ${coloured && "coloured"} ${white && "white"} ${big && "big"} ${medium && "medium"}`}
      onClick={() =>
        window.open(
          "https://play.google.com/store/apps/details?id=com.socourt.immotech"
        )
      }
    >
      {white ?
      <img src={require("../../assets/icons/Google-Play-Badge-White.svg")} /> :
      <img src={require("../../assets/icons/Google-Play-Badge.svg")} />}

      {/* <div className="google-icon" />
      <div className="google-text">
        <p>{translate("homepageAvailableIn")}</p>
        <p id="bigger">Google Play</p>
      </div> */}
    </div>
  );
}

export default GooglePlay;
