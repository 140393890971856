import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import "./styles.scss";

function MonthlyRent() {
  const [selectedTab, setSelectedTab] = useState(
    translate("monthlyRentTogether")
  );
  const [currentImage, setCurrentImage] = useState(
    "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-1.png"
  );

  const language = window.localStorage.getItem("immoLanguage");

  useEffect(() => {
    setSelectedTab(translate("monthlyRentTogether"));
  }, [language]);

  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("monthlyRent")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("monthlyRentTogether")}
          </h3>
          {translate("monthlyRentTogetherDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("monthlyRentTogetherDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-1-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-1.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("monthlyRentPayment")}
          </h3>
          {translate("monthlyRentPaymentDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("monthlyRentPaymentDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-2-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-2.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("monthlyRentHistory")}
          </h3>
          {translate("monthlyRentHistoryDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("monthlyRentHistoryDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-3-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-3.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("monthlyRentTermination")}
          </h3>
          {translate("monthlyRentTerminationDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("monthlyRentTerminationDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-4-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-4.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("monthlyRentCommunication")}
          </h3>
          {translate("monthlyRentCommunicationDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("monthlyRentCommunicationDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-5-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-5.png"
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="monthly-rent-container">
      <div
        className="left-container"
        style={{ backgroundImage: `url(${currentImage})` }}
      ></div>
      <div className="right-container">
        <div
          className="back-btn"
          onClick={() => history.push("/Functionality")}
        >
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <div className="inner-container">
          <TitleComponent title={translate("monthlyRent")} />
          <Tabs
            wide
            tabs={[
              translate("monthlyRentTogether"),
              translate("monthlyRentPayment"),
              translate("monthlyRentHistory"),
              translate("monthlyRentTermination"),
              translate("monthlyRentCommunication"),
            ]}
            selected={selectedTab}
            onSelect={(value) => {
              setCurrentImage(null);
              setTimeout(() => {
                if (value === translate("monthlyRentTogether")) {
                  setCurrentImage(
                    language === "en"
                    ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-1-en.png"
                    : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-1.png"
                  );
                } else if (value === translate("monthlyRentPayment")) {
                  setCurrentImage(
                    language === "en"
                    ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-2-en.png"
                    : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-2.png"
                  );
                } else if (value === translate("monthlyRentHistory")) {
                  setCurrentImage(
                    language === "en"
                    ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-3-en.png"
                    : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-3.png"
                  );
                } else if (value === translate("monthlyRentTermination")) {
                  setCurrentImage(
                    language === "en"
                    ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-4-en.png"
                    : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-4.png"
                  );
                } else if (value === translate("monthlyRentCommunication")) {
                  setCurrentImage(
                    language === "en"
                    ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-5-en.png"
                    : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-rent-5.png"
                  );
                }
              }, 10);
              setSelectedTab(value);
            }}
          />
          {selectedTab === translate("monthlyRentTogether") ? (
            <>
              {translate("monthlyRentTogetherDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("monthlyRentTogetherDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("monthlyRentPayment") ? (
            <>
              {translate("monthlyRentPaymentDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("monthlyRentPaymentDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("monthlyRentHistory") ? (
            <>
              {translate("monthlyRentHistoryDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("monthlyRentHistoryDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("monthlyRentTermination") ? (
            <>
              {translate("monthlyRentTerminationDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {translate("monthlyRentTerminationDescription")[index]}
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("monthlyRentCommunication") ? (
            <>
              {translate("monthlyRentCommunicationDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("monthlyRentCommunicationDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default MonthlyRent;
