import { BlogTypes } from "../actions";

const initialState = {
  currentBlog: null,
  articles: { docs: [], totalPages: 0 },
  article: {}
  // articles: [],
  // article: {}
};

const handleNewDocs = (state, payload, key) => {
  if (payload.page === 1) return payload.docs;
  if (!payload?.docs) return 
  const _docs = payload.docs.filter(
    (newDoc) => !state[key].docs.find((oldDoc) => newDoc._id === oldDoc._id)
  );
  return [...state?.[key]?.docs, ..._docs];
};

const blog = (state = initialState, action) => {
  switch (action.type) {
    case BlogTypes.SET_BLOG_CONTENT:
      return { ...state, currentBlog: action.payload, article: action.payload };

      case BlogTypes.GET_ARTICLES_SUCCESS:
        return { ...state, articles: { docs: handleNewDocs(state, action.payload, 'articles'), totalPages: action.payload.totalPages }, article: {} };
      case BlogTypes.GET_ARTICLE_SUCCESS:
        return {...state,  article: {...action.payload} };

    // case BlogTypes.GET_ARTICLES_SUCCESS:
    //   return { ...state, articles: action.payload.articles };
    // case BlogTypes.GET_ARTICLE_SUCCESS:
    //   return { ...state, article: action.payload.article };
    default:
      return state;
  }
};

export default blog;
