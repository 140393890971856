import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";
import { setLocale, translate } from "../../translations/localization";
import { useDispatch } from "react-redux";
import { setLanguageLocale } from "../../actions";

function MobileHeader() {
  const [opened, setOpened] = useState(false);
  const [language, setLanguage] = useState("");
  const languageCode = localStorage.getItem("immoLanguage");
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (opened) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "scroll";
    }
  }, [opened]);

  useEffect(() => {
    if (!languageCode) {
      localStorage.setItem("immoLanguage", "bg");
      setLanguage("bg");
      setLocale("bg");
    } else {
      setLanguage(languageCode);
    }
  }, [languageCode]);

  return (
    <div className="mobile-header-container">
      {opened ? (
        <div className="opened-header-container">
          <div className="upper-container">
            <div className="close-btn" onClick={() => setOpened(false)} />
            <div className="flex-column">
              <div
                className={`mobile-header-element ${location.pathname === "/" && "selected"}`}
                onClick={() => {
                  history.push("/");
                  setOpened(false);
                }}
              >
                {translate("navMenuHomepage")}
              </div>
              <div
                className={`mobile-header-element ${location.pathname === "/Functionality" && "selected"}`}
                onClick={() => {
                  localStorage.setItem("lastPositionY", 0);
                  history.push("/Functionality");
                  setOpened(false);
                }}
              >
                {translate("navMenuFunctionalities")}
              </div>
              {/* <div
                className={`mobile-header-element ${
                  location.pathname === "/FAQ" && "selected"
                }`}
                onClick={() => {
                  history.push("/FAQ");
                  setOpened(false);
                }}
              >
                {translate("navMenuHowItWorks")}
              </div> */}
              <div
                className={`mobile-header-element ${location.pathname === "/Subscription" && "selected"}`}
                onClick={() => {
                  history.push("/Subscription");
                  setOpened(false);
                }}
              >
                {translate("navMenuSubscription")}
              </div>
              <div
                className={`mobile-header-element ${location.pathname === "/AboutUs" && "selected"}`}
                onClick={() => {
                  history.push("/AboutUs");
                  setOpened(false);
                }}
              >
                {translate("navMenuAboutUs")}
              </div>
              <div
                className={`mobile-header-element ${(location.pathname === "/Blog" || location.pathname.includes("SingleBlog")) && "selected"}`}
                onClick={() => {
                  history.push("/Blog");
                  setOpened(false);
                }}
              >
                {translate("navMenuBlog")}
              </div>
            </div>
            <div className="flex-container languages">
              <div
                className={`language-button margin ${language === "en" && "selected"}`}
                onClick={() => {
                  setLanguage("en");
                  setLocale("en");
                  dispatch(setLanguageLocale("en"));
                  localStorage.setItem("immoLanguage", "en");
                  history.push(location.pathname + location.search);
                }}
              >
                EN
              </div>
              <div
                className={`language-button margin ${language === "bg" && "selected"}`}
                onClick={() => {
                  setLanguage("bg");
                  setLocale("bg");
                  dispatch(setLanguageLocale("bg"));
                  localStorage.setItem("immoLanguage", "bg");
                  history.push(location.pathname + location.search);
                }}
              >
                BG
              </div>
              <div
                className={`language-button margin ${language === "de" && "selected"}`}
                onClick={() => {
                  setLanguage("de");
                  setLocale("de");
                  dispatch(setLanguageLocale("de"));
                  localStorage.setItem("immoLanguage", "de");
                  history.push(location.pathname + location.search);
                }}
              >
                DE
              </div>
              <div
                className={`language-button margin ${language === "it" && "selected"}`}
                onClick={() => {
                  setLanguage("it");
                  setLocale("it");
                  dispatch(setLanguageLocale("it"));
                  localStorage.setItem("immoLanguage", "it");
                  history.push(location.pathname + location.search);
                }}
              >
                IT
              </div>
            </div>
          </div>
          <div className="lower-container" />
        </div>
      ) : (
        <div className="unopened-header-container">
          <div className="open-icon" onClick={() => setOpened(true)} />
          <div className="logo" onClick={() => history.push("/")} />
        </div>
      )}
    </div>
  );
}

export default withRouter(MobileHeader);
