import React, { useEffect } from "react";
import { MainComponent, DownloadAppSection, TabsComponent, GridHomeComponent, Section } from "../../components";
import "./styles.scss";
import { translate } from "../../translations/localization";
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";

function Home() {
  const language = window.localStorage.getItem("immoLanguage");

  return (
    <div className="home-container">
      <MainComponent
        image="https://immotech-web.s3.eu-central-1.amazonaws.com/images/Homepage-Section-1.png"
        ads
        opacity
        mobileLeft
        title="IMMOTECH"
        text={[
          {
            subtitle: translate("homepageRentControll"),
            paragraph: translate("homepageRentControllDescription"),
          },
        ]}
      />
      <MainComponent
        image={language === "bg" ? require("../../assets/images/Homepage-Section-2.png") : translate("homepage2image")}
        left
        divider
        title={translate("homepageImmotechForEveryone")}
        LeftLine={1}
        text={[
          {
            subtitle: translate("homepageForLandlordWithoutTenant"),
            paragraph: translate("homepageForLandlordWithoutTenantDescription"),
          },
          {
            subtitle: translate("homepageForLandlordWithTenant"),
            paragraph: translate("homepageForLandlordWithTenantDescription"),
          },
          {
            subtitle: translate("homepageForTenant"),
            paragraph: translate("homepageForTenantDescription"),
          },
        ]}
      />
      <MainComponent
        image={translate("homepage3image")}
        // opacity
        halfImg
        subtitleMargin
        mobileSpecial={window.innerWidth < 900}
        LeftLine={1}
        mobileLeft
        title={translate("homepageFunctionalities")}
        text={[
          {
            subtitle: translate("homepageInnovativeTechnology"),
          },
          {
            paragraph: translate("homepageInnovativeTechnologyFirstTab"),
          },
          {
            paragraph: translate("homepageInnovativeTechnologySecondTab"),
          },
          {
            paragraph: translate("homepageInnovativeTechnologyThirdTab"),
          },
          {
            paragraph: translate("homepageInnovativeTechnologyFourthTab"),
          },
          {
            paragraph: translate("homepageInnovativeTechnologyFifthTab"),
          },
        ]}
      />

      <DownloadAppSection
        image="https://immotech-web.s3.eu-central-1.amazonaws.com/images/Download-section-background.png"
        title={translate("footerDownload")}
      />
      {window.innerWidth < 900 ? (
        <TabsComponent
          divider
          title={translate("homepageHowItWorks")}
          subtitle={translate("homepageHowItWorksInnerTitle")}
          tabs={[translate("homepageStepOne"), translate("homepageStepTwo"), translate("homepageStepThree")]}
          tabsText={[translate("homepageStepOneDescription"), translate("homepageStepTwoDescription"), translate("homepageStepThreeDescription")]}
          backgroundImages={[translate("homepage5-1image"), translate("homepage5-2image"), translate("homepage5-3image")]}
        />
      ) : (
        <LazyLoadComponent>
          <TabsComponent
            divider
            title={translate("homepageHowItWorks")}
            subtitle={translate("homepageHowItWorksInnerTitle")}
            tabs={[translate("homepageStepOne"), translate("homepageStepTwo"), translate("homepageStepThree")]}
            tabsText={[translate("homepageStepOneDescription"), translate("homepageStepTwoDescription"), translate("homepageStepThreeDescription")]}
            backgroundImages={[translate("homepage5-1image"), translate("homepage5-2image"), translate("homepage5-3image")]}
          />
        </LazyLoadComponent>
      )}

      <GridHomeComponent />
    </div>
  );
}

export default Home;
