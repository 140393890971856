import { combineEpics } from 'redux-observable'
import * as starter from './starter'
import * as contact from './contact'
import * as blog from './blog'

export const rootEpic = combineEpics(
  starter.loadingEpic,
  contact.sendEmail,
  blog.getArticles,
  blog.getArticle
)
