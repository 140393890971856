import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import "./styles.scss";

function Neighbours() {
  const [selectedTab, setSelectedTab] = useState(translate("neighboursTable"));
  const [currentImage, setCurrentImage] = useState(
    "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-profile-1.png"
  );

  const language = window.localStorage.getItem("immoLanguage");

  useEffect(() => {
    setSelectedTab(translate("neighboursTable"));
  }, [language]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("neighbours")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("neighboursTable")}
          </h3>
          {translate("neighboursTableDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("neighboursTableDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-1-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-1.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("neighboursCalendar")}
          </h3>
          {translate("neighboursCalendarDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("neighboursCalendarDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-2-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-2.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("neighboursVoting")}
          </h3>
          {translate("neighboursVotingDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("neighboursVotingDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-3-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-3.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("neighboursList")}
          </h3>
          {translate("neighboursListDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("neighboursListDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-4-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-4.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("neighboursCase")}
          </h3>
          {translate("neighboursCaseDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("neighboursCaseDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-5-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-5.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("neighboursChat")}
          </h3>
          {translate("neighboursChatDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("neighboursChatDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-6-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-6.png"
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("neighboursHousemanager")}
          </h3>
          {translate("neighboursHousemanagerDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("neighboursHousemanagerDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-7-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-7.png"
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="neighbours-container">
      <div
        className="left-container"
        style={{ backgroundImage: `url(${currentImage})` }}
      ></div>
      <div className="right-container">
        <div
          className="back-btn"
          onClick={() => history.push("/Functionality")}
        >
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <div className="inner-container">
          <TitleComponent title={translate("neighbours")} />
          <Tabs
            wide
            superwide
            tabs={[
              translate("neighboursTable"),
              translate("neighboursCalendar"),
              translate("neighboursVoting"),
              translate("neighboursList"),
              translate("neighboursCase"),
              translate("neighboursChat"),
              translate("neighboursHousemanager"),
            ]}
            selected={selectedTab}
            onSelect={(value) => {
              setCurrentImage(null);
              setTimeout(() => {
                if (value === translate("neighboursTable")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-1-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-1.png"
                  );
                } else if (value === translate("neighboursCalendar")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-2-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-2.png"
                  );
                } else if (value === translate("neighboursVoting")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-3-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-3.png"
                  );
                } else if (value === translate("neighboursList")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-4-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-4.png"
                  );
                } else if (value === translate("neighboursCase")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-5-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-5.png"
                  );
                } else if (value === translate("neighboursChat")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-6-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-6.png"
                  );
                } else if (value === translate("neighboursHousemanager")) {
                  setCurrentImage(
                    language === "en"
                ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-7-en.png"
                : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-neighbours-7.png"
                  );
                }
              }, 10);
              setSelectedTab(value);
            }}
          />
          {selectedTab === translate("neighboursTable") ? (
            <>
              {translate("neighboursTableDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("neighboursTableDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("neighboursCalendar") ? (
            <>
              {translate("neighboursCalendarDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("neighboursCalendarDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("neighboursVoting") ? (
            <>
              {translate("neighboursVotingDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("neighboursVotingDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("neighboursList") ? (
            <>
              {translate("neighboursListDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("neighboursListDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("neighboursCase") ? (
            <>
              {translate("neighboursCaseDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("neighboursCaseDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("neighboursChat") ? (
            <>
              {translate("neighboursChatDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("neighboursChatDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("neighboursHousemanager") ? (
            <>
              {translate("neighboursHousemanagerDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {translate("neighboursHousemanagerDescription")[index]}
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Neighbours;
