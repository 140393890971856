export const StarterTypes = {
  START_LOADING: "starter/START_LOADING",
  STOP_LOADING: "starter/STOP_LOADING",
  SET_LANGUAGE: "starter/SET_LANGUAGE",
};

export const startLoading = (payload) => ({
  type: StarterTypes.START_LOADING,
  payload,
});

export const stopLoading = (payload) => ({
  type: StarterTypes.STOP_LOADING,
  payload,
});
export const setLanguageLocale = (payload) => ({
  type: StarterTypes.SET_LANGUAGE,
  payload,
});
