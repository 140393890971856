import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import './styles.scss';

export default function FunctionalitiesDocuments() {
    const language = window.localStorage.getItem("immoLanguage");

    const [selectedTab, setSelectedTab] = useState(
      translate("functionalitiesSectionPhotosTabOne")
    );
    const [currentImage, setCurrentImage] = useState(
      language === "bg"
        ? require("../../assets/images/Files-files.png")
        : translate("functionalityDocumentsImageTabOne")
    );
  
    useEffect(() => {
      setSelectedTab(translate("functionalitiesSectionPhotosTabOne"));
      if (language === 'bg') setCurrentImage(require("../../assets/images/Files-files.png"))
      else translate("functionalityDocumentsImageTabOne")
    }, [language]);
  
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("functionalitiesSectionPhotos")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesSectionPhotosTabOne")}
          </h3>
          {translate("functionalitiesSectionPhotosTabOneDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesSectionPhotosTabOneDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Property-management-rent-payment.png")
                : translate("functionalityDocumentsImageTabOne")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesSectionPhotosTabTwo")}
          </h3>
          {translate("functionalitiesSectionPhotosTabTwoDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesSectionPhotosTabTwoDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Property-management-activites.png")
                : translate("functionalityDocumentsImageTabTwo")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesSectionPhotosTabThree")}
          </h3>
          {translate("functionalitiesSectionPhotosTabThreeDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesSectionPhotosTabThreeDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Property-management-info.png")
                : translate("functionalityDocumentsImageTabThree")
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="functionalities-section-screen functionalities-documents-container">
      <div
        className="left-container"
        style={{ backgroundImage: `url(${currentImage})` }}
      ></div>
      <div className="right-container">
        <div
          className="back-btn"
          onClick={() => history.push("/Functionality")}
        >
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <div className="inner-container">
          <TitleComponent title={translate("functionalitiesSectionPhotos")} />
          <Tabs
            wide
            tabs={[
              translate("functionalitiesSectionPhotosTabOne"),
              translate("functionalitiesSectionPhotosTabTwo"),
              translate("functionalitiesSectionPhotosTabThree"),
            ]}
            selected={selectedTab}
            onSelect={(value) => {
              // setCurrentImage(null);
              setTimeout(() => {
                if (value === translate("functionalitiesSectionPhotosTabOne")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Files-files.png")
                    : translate("functionalityDocumentsImageTabOne")
                  );
                } else if (value === translate("functionalitiesSectionPhotosTabTwo")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Files-important-date.png")
                    : translate("functionalityDocumentsImageTabTwo")
                  );
                } else if (value === translate("functionalitiesSectionPhotosTabThree")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Files-options.png")
                    : translate("functionalityDocumentsImageTabThree")
                  );
                }
              }, 10);
              setSelectedTab(value);
            }}
          />
          {selectedTab === translate("functionalitiesSectionPhotosTabOne") ? (
            <>
              {translate("functionalitiesSectionPhotosTabOneDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("functionalitiesSectionPhotosTabOneDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("functionalitiesSectionPhotosTabTwo") ? (
            <>
              {translate("functionalitiesSectionPhotosTabTwoDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {translate("functionalitiesSectionPhotosTabTwoDescription")[index]}
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesSectionPhotosTabThree") ? (
            <>
              {translate("functionalitiesSectionPhotosTabThreeDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesSectionPhotosTabThreeDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
