import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import './styles.scss';

export default function FunctionalitiesManagement() {
    const language = window.localStorage.getItem("immoLanguage");

    const [selectedTab, setSelectedTab] = useState(
      translate("functionalitiesManagingEasilyTabOne")
    );
    const [currentImage, setCurrentImage] = useState(
      language === "bg"
        ? require("../../assets/images/Property-management-rent-payment.png")
        : translate("functionalityManagementImageTabOne")
    );
    const [prevImage, setPrevImage] = useState(require("../../assets/images/Property-management-rent-payment.png"));

    // const [currentImage, setCurrentImage] = useState(
    //   language === "en"
    //     ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-1-en.png"
    //     : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-bills-1.png"
    // );
  
    useEffect(() => {
      setSelectedTab(translate("functionalitiesManagingEasilyTabOne"));
      if (language === 'bg') setCurrentImage(require("../../assets/images/Property-management-rent-payment.png"))
      else setCurrentImage(translate("functionalityManagementImageTabOne"))
    }, [language]);
  
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("functionalitiesManagingEasily")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesManagingEasilyTabOne")}
          </h3>
          {translate("functionalitiesManagingEasilyTabOneDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesManagingEasilyTabOneDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Property-management-rent-payment.png")
                : translate("functionalityManagementImageTabOne")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesManagingEasilyTabTwo")}
          </h3>
          {translate("functionalitiesManagingEasilyTabTwoDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesManagingEasilyTabTwoDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Property-management-activites.png")
                : translate("functionalityManagementImageTabTwo")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesManagingEasilyTabThree")}
          </h3>
          {translate("functionalitiesManagingEasilyTabThreeDescription").map((el, index) => {
            return (
              <div className="line-and-text">
                <div className="left-line" />
                <p>{translate("functionalitiesManagingEasilyTabThreeDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Property-management-info.png")
                : translate("functionalityManagementImageTabThree")
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="functionalities-section-screen functionalities-management-container">
      <div
        className="left-container"
        // style={{ backgroundImage: `url(${currentImage ? currentImage : prevImage})` }}
        style={{ backgroundImage: `url(${currentImage})` }}
      ></div>
      {/* <img src={require("../../assets/images/Property-management-rent-payment.png")} /> */}
      <div className="right-container">
        <div
          className="back-btn"
          onClick={() => history.push("/Functionality")}
        >
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <div className="inner-container">
          <TitleComponent title={translate("functionalitiesManagingEasily")} />
          <Tabs
            wide
            tabs={[
              translate("functionalitiesManagingEasilyTabOne"),
              translate("functionalitiesManagingEasilyTabTwo"),
              translate("functionalitiesManagingEasilyTabThree"),
            ]}
            selected={selectedTab}
            onSelect={(value) => {
              setCurrentImage(null);
              setTimeout(() => {
                if (value === translate("functionalitiesManagingEasilyTabOne")) {
                  // setCurrentImage(
                  //   language === "en"
                  //   ? require("../../assets/images/Property-management-rent-payment.png")
                  //   : require("../../assets/images/Property-management-rent-payment.png")
                  // );
                  if (language === 'bg') setCurrentImage(require("../../assets/images/Property-management-rent-payment.png"))
                  else setCurrentImage(translate("functionalityManagementImageTabOne"))
                } else if (value === translate("functionalitiesManagingEasilyTabTwo")) {
                  // setCurrentImage(
                  //   language === "en"
                  //   ? require("../../assets/images/Property-management-activites.png")
                  //   : require("../../assets/images/Property-management-activites.png")
                  // );
                  if (language === 'bg') setCurrentImage(require("../../assets/images/Property-management-activites.png"))
                  else setCurrentImage(translate("functionalityManagementImageTabTwo"))
                } else if (value === translate("functionalitiesManagingEasilyTabThree")) {
                  // setCurrentImage(
                  //   language === "en"
                  //   ? require("../../assets/images/Property-management-info.png")
                  //   : require("../../assets/images/Property-management-info.png")
                  // );
                  if (language === 'bg') setCurrentImage(require("../../assets/images/Property-management-info.png"))
                  else setCurrentImage(translate("functionalityManagementImageTabThree"))
                }
              }, 10);
              setSelectedTab(value);
            }}
          />
          {selectedTab === translate("functionalitiesManagingEasilyTabOne") ? (
            <>
              {translate("functionalitiesManagingEasilyTabOneDescription").map((el, index) => {
                return (
                  <div className="line-and-text">
                    <div className="left-line" />
                    <p>{translate("functionalitiesManagingEasilyTabOneDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("functionalitiesManagingEasilyTabTwo") ? (
            <>
              {translate("functionalitiesManagingEasilyTabTwoDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {translate("functionalitiesManagingEasilyTabTwoDescription")[index]}
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesManagingEasilyTabThree") ? (
            <>
              {translate("functionalitiesManagingEasilyTabThreeDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text">
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesManagingEasilyTabThreeDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
