import React, { useEffect, useState } from "react";
import { Tabs, TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import './styles.scss';

export default function FunctionalitiesChat() {
    const language = window.localStorage.getItem("immoLanguage");

    // const [imageLoaded, setImageLoaded] = useState(true);
    // const [nextImage, setNextImage] = useState(null);
    const [selectedTab, setSelectedTab] = useState(
      translate("functionalitiesChatTabOne")
    );
    const [currentImage, setCurrentImage] = useState(
      language === "bg"
        ? require("../../assets/images/Chat-communication.png")
        : translate("functionalityChatImageTabOne")
    );

    // const handleTabChange = (value) => {
    //   setSelectedTab(value);
    //   let newImage;
    //   if (value === translate("functionalitiesChatTabOne")) {
    //     setNextImage(
    //       language === "bg"
    //       ? require("../../assets/images/Chat-communication.png")
    //       : translate("functionalityChatImageTabOne")
    //     );
    //   } else if (value === translate("functionalitiesChatTabTwo")) {
    //     setNextImage(
    //       language === "bg"
    //       ? require("../../assets/images/Chat-list.png")
    //       : translate("functionalityChatImageTabTwo")
    //     );
    //   } else if (value === translate("functionalitiesChatTabThree")) {
    //     setNextImage(
    //       language === "bg"
    //       ? require("../../assets/images/Chat-video.png")
    //       : translate("functionalityChatImageTabThree")
    //     );
    //   } else if (value === translate("functionalitiesChatTabFour")) {
    //     setNextImage(
    //       language === "bg"
    //       ? require("../../assets/images/Chat-settings.png")
    //       : translate("functionalityChatImageTabFour")
    //     );
    //   }
  
    //   // setNextImage(newImage);
    // };
  
    useEffect(() => {
      setSelectedTab(translate("functionalitiesChatTabOne"));
      if (language === 'bg') setCurrentImage(require("../../assets/images/Chat-communication.png"))
      else translate("functionalityChatImageTabOne")
    }, [language]);
  
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    // useEffect(() => {
    //   if (nextImage) {
    //     setImageLoaded(false);
    //     const img = new Image();
    //     img.onload = () => {
    //       setCurrentImage(nextImage);
    //       setImageLoaded(true);
    //       setNextImage(null);
    //     };
    //     img.src = nextImage;
    //   }
    // }, [nextImage]);


  return window.innerWidth < 900 ? (
    <div className="smart-profile-mobile-container">
      <div className="back-btn" onClick={() => history.goBack()}>
        <div className="arrow-back" />
        <p>{translate("gobackwards")}</p>
      </div>
      <TitleComponent title={translate("functionalitiesChat")} />
      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesChatTabOne")}
          </h3>
          {translate("functionalitiesChatTabOneDescription").map((el, index) => {
            return (
              <div className="line-and-text" key={`${el}-index`}>
                <div className="left-line" />
                <p>{translate("functionalitiesChatTabOneDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Chat-communication.png")
                : translate("functionalityChatImageTabOne")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesChatTabTwo")}
          </h3>
          {translate("functionalitiesChatTabTwoDescription").map((el, index) => {
            return (
              <div className="line-and-text" key={`${el}-0-index`}>
                <div className="left-line" />
                <p>{translate("functionalitiesChatTabTwoDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Chat-list.png")
                : translate("functionalityChatImageTabTwo")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesChatTabThree")}
          </h3>
          {translate("functionalitiesChatTabThreeDescription").map((el, index) => {
            return (
              <div className="line-and-text" key={`${el}-1-index`}>
                <div className="left-line" />
                <p>{translate("functionalitiesChatTabThreeDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Chat-video.png")
                : translate("functionalityChatImageTabThree")
            })`,
          }}
        ></div>
      </div>

      <div className="mobile-content">
        <div className="mobile-text">
          <h3 style={{ marginBottom: "20px" }}>
            {translate("functionalitiesChatTabFour")}
          </h3>
          {translate("functionalitiesChatTabFourDescription").map((el, index) => {
            return (
              <div className="line-and-text" key={`${el}-1-index`}>
                <div className="left-line" />
                <p>{translate("functionalitiesChatTabFourDescription")[index]}</p>
              </div>
            );
          })}
        </div>
        <div
          className="mobile-image one"
          style={{
            backgroundImage: `url(${
              language === "bg"
                ? require("../../assets/images/Chat-settings.png")
                : translate("functionalityChatImageTabFour")
            })`,
          }}
        ></div>
      </div>
    </div>
  ) : (
    <div className="functionalities-section-screen functionalities-chat-container">
      <div
        className="left-container"
        style={{ backgroundImage: `url(${currentImage})`,
        //  opacity: imageLoaded ? 1 : 0 
        }}
      ></div>
      <div className="right-container">
        <div
          className="back-btn"
          onClick={() => history.push("/Functionality")}
        >
          <div className="arrow-back" />
          <p>{translate("gobackwards")}</p>
        </div>
        <div className="inner-container">
          <TitleComponent title={translate("functionalitiesChat")} />
          <Tabs
            wide
            tabs={[
              translate("functionalitiesChatTabOne"),
              translate("functionalitiesChatTabTwo"),
              translate("functionalitiesChatTabThree"),
              translate("functionalitiesChatTabFour"),
            ]}
            selected={selectedTab}
            onSelect={(value) => {
              // setCurrentImage(null);
              // handleTabChange(value)
              setTimeout(() => {
                if (value === translate("functionalitiesChatTabOne")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Chat-communication.png")
                    : translate("functionalityChatImageTabOne")
                  );
                } else if (value === translate("functionalitiesChatTabTwo")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Chat-list.png")
                    : translate("functionalityChatImageTabTwo")
                  );
                } else if (value === translate("functionalitiesChatTabThree")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Chat-video.png")
                    : translate("functionalityChatImageTabThree")
                  );
                } else if (value === translate("functionalitiesChatTabFour")) {
                  setCurrentImage(
                    language === "bg"
                    ? require("../../assets/images/Chat-settings.png")
                    : translate("functionalityChatImageTabFour")
                  );
                }
              }, 10);
              setSelectedTab(value);
            }}
          />
          {selectedTab === translate("functionalitiesChatTabOne") ? (
            <>
              {translate("functionalitiesChatTabOneDescription").map((el, index) => {
                return (
                  <div className="line-and-text" key={`${el}-2-index`}>
                    <div className="left-line" />
                    <p>{translate("functionalitiesChatTabOneDescription")[index]}</p>
                  </div>
                );
              })}
            </>
          ) : selectedTab === translate("functionalitiesChatTabTwo") ? (
            <>
              {translate("functionalitiesChatTabTwoDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text" key={`${el}-3-index`}>
                      <div className="left-line" />
                      <p>
                        {translate("functionalitiesChatTabTwoDescription")[index]}
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesChatTabThree") ? (
            <>
              {translate("functionalitiesChatTabThreeDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text" key={`${el}-4-index`}>
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesChatTabThreeDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : selectedTab === translate("functionalitiesChatTabFour") ? (
            <>
              {translate("functionalitiesChatTabFourDescription").map(
                (el, index) => {
                  return (
                    <div className="line-and-text" key={`${el}-4-index`}>
                      <div className="left-line" />
                      <p>
                        {
                          translate("functionalitiesChatTabFourDescription")[
                            index
                          ]
                        }
                      </p>
                    </div>
                  );
                }
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
