import React from "react";
import "./styles.scss";

function TitleComponent({ title, small, smallest, mobileSpecial, noline, ml, moveTitleLeft, color, mt, titleUppercase, specialLine }) {
  return (
    <div className={`title-container ${moveTitleLeft && "moveLeft"} ${mobileSpecial && "mobile-special"} ${small && "small"} ${smallest && "smallest"}`} style={{ marginLeft: `${ml}%`, color: color, marginTop: `${mt}%` }}>
      <h1 className={titleUppercase ? "uppercase" : ""}>{title}</h1>
      {noline ? null : <div className={`green-line ${specialLine && "special"}`} />}
    </div>
  );
}

export default TitleComponent;
