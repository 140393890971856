import React, { useEffect, useState } from "react";
import { AppStore, GooglePlay } from "../../components";
import "./styles.scss";
import { translate } from "../../translations/localization";
import { useSelector } from "react-redux";

function Footer() {
  const language = useSelector(({ starter }) => starter?.language) || "";
  console.log(language);
  return (
    <div className="footer-container">
      <div className="upper-footer-container">
        <div className="location-container element">
          <div className="location-inner-container">
            <h3 style={{ marginBottom: "15px" }}>{translate("ourLocations")}</h3>
            <b style={{ fontSize: "20px" }}>{translate("аboutUsTab1")}</b>
            <p style={{ fontSize: "18px" }}>{translate("aboutUsAddress")?.split(",")[0]}, 1407</p>
            <p style={{ fontSize: "18px", marginBottom: "20px" }}>
              {translate("aboutUsRegion")?.split(",")[0]} ({translate("aboutUsOfficeA")})
            </p>
            <b style={{ fontSize: "20px" }}>{translate("аboutUsTab2")}</b>
            <p style={{ fontSize: "18px" }}>{translate("aboutUsAddressGermany")?.split(",")[0]}, 60549</p>
            <p style={{ fontSize: "18px" }}>{translate("aboutUsCityGermany")}</p>
          </div>
        </div>
        <div className="socials-container element">
          <div className="socials-inner-container">
            <h3>{translate("footerContactUs")}</h3>
            <p>E: support@immotech-app.com</p>
            <p>T:+359 2 9631330 / +49 163 4193803</p>
            <h3 className="margin">{translate("homepageFollowUs")}</h3>
            <div className="flex-container mobile">
              <div className="social facebook" onClick={() => window.open("https://www.facebook.com/immotechapp/")} />
              <div className="social instagram" onClick={() => window.open("https://www.instagram.com/immotech2020/")} />
              <div className="social linkedin" onClick={() => window.open("https://www.linkedin.com/company/immotech-app/?originalSubdomain=bg")} />
              <div className="social youtube" onClick={() => window.open("https://www.youtube.com/@immotech5709/videos")} />
              {/* <div className="social linkedin" /> */}
            </div>
          </div>
        </div>
        <div className="app-info-container element">
          <div className="app-info-inner-container" style={{ height: "100%" }}>
            <h3 style={{ marginBottom: "50px" }}>{translate("footerDownload")}</h3>

            <div className="flex-container">
              {window.innerWidth > 900 && <img className="qr-code" src={require("../../assets/icons/QR.svg")} alt="qr code" />}

              <div className="flex-container col">
                <AppStore white medium />
                <GooglePlay white medium />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lower-footer-container">
        <div className="text-wrapper">
          <p className="file-link">{translate("footerPrivacyPolicy")}</p>
          <p className="file-link">{translate("footerCookiePolicy")}</p>
          <p className="file-link">{translate("footerRulesOfUse")}</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
