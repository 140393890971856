import React from "react";
import { translate } from "../../translations/localization";
import "./styles.scss";

function AppStore({ coloured, big, medium, white }) {
  return (
    <div
      className={`app-store-container ${coloured && "coloured"} ${white && "white"} ${big && "big"} ${medium && "medium"}`}
      onClick={() =>
        window.open("https://apps.apple.com/bg/app/immotech/id1467332873")
      }
    >
      {white ?
      <img src={require("../../assets/icons/App-Store-Badge-White.svg")} /> :
      <img src={require("../../assets/icons/App-Store-Badge.svg")} />}
      {/* <div className="apple-icon" />
      <div className="apple-text">
        <p>{translate("homepageDownloadFrom")}</p>
        <p id="bigger">App Store</p>
      </div> */}
    </div>
  );
}

export default AppStore;
