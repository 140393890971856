import React, { useState, useEffect, useRef } from "react";
import { Tabs } from "../../components";
import { CategoryComponent } from "./CategoryComponent";
import "./styles.scss";
import { translate } from "../../translations/localization";

function QuestionsComponent() {

  const mobile = [ translate("tab1"),
  translate("tab2"),
  translate("tab3"),
  translate("tab4"),
  translate("tab5"),
  translate("tab6"),
  translate("tab7"),
  translate("tab8")]

  const [selected, setSelected] = useState(translate("tab1"));
  const [selectedQuestion, setSelectedQuestion] = useState(translate(translate("tab1"))[0]);

  useEffect(() => {
    setSelectedQuestion(translate(selected)[0]);
    // eslint-disable-next-line
  }, [selected]);

  return (
    


    <div className="questions-component-container">
      <div className="top-banner">
        <h1>{translate("howItWorksFAQ")}</h1>
      </div>
      {window.innerWidth < 900 ? (
        <>
          {mobile.map(key => {
            return (<CategoryComponent categoryData={translate(key)} categoryName={key} />)
          })}
        </>
      ) : (
        <div className="inner-content">
          <div className="horizontal-scroll">
            <Tabs
              wide
              selected={selected}
              tabs={[
                translate("tab1"),
                translate("tab2"),
                translate("tab3"),
                translate("tab4"),
                translate("tab5"),
                translate("tab6"),
                translate("tab7"),
                translate("tab8")
              ]}
              onSelect={(value) => setSelected(value)}
            />
          </div>
          <div className="faq-container">
            <div className="left-container">
              {translate(`${selected}`).map((question,i) => {
                return (
                  <div
                    className={`question-container ${
                      selectedQuestion &&
                      selectedQuestion.question === question.question &&
                      "selected"
                    }`}
                    onClick={() => setSelectedQuestion(question)}
                  >
                    <div className="question">{question.question}</div>
                  </div>
                );
              })}
            </div>
            <div className="right-container">
              <div className="arrow-container">
                <div className="line" />
                <div className="arrow-right" />
              </div>
              <p>{selectedQuestion && selectedQuestion.answer}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuestionsComponent;
