import React from "react";
import "./styles.scss";

function Tabs({ tabs, selected, onSelect, wide, superwide, style, tabStyle }) {
  return (
    <div className={`tabs-container ${wide && "wide"} ${superwide && "superwide"}`} style={style}>
      {tabs.map((tab, i) => {
        return (
          <div key={`${i}-element-tab-key`} className={`tab-element ${selected === tab && "selected"} `} style={tabStyle} onClick={() => onSelect(tab)}>
            {tab}
          </div>
        );
      })}
    </div>
  );
}

export default Tabs;
