import React,{useEffect} from "react";
import { MainComponent, DownloadAppSection, Section } from "../../components";
import "./styles.scss";
import {translate} from "../../translations/localization"

function Subscription() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },[])
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
  const language = window.localStorage.getItem("immoLanguage");
  return (
    <div className="subscription-container">

      {/* old */}
      {/* <MainComponent
        image={require("../../assets/images/abonament-cql-mesec-min.png")}
        opacity
        title={translate("subscriptionOneMonthFree")}
        text={[
          {
            subtitle: "",
            paragraph: translate("subscriptionOneMonthFreeDescription"),
          },
        ]}
      /> */}

      {/* new */}
      <MainComponent
        // image={require("../../assets/images/homepage-section-1.png")}
        image="https://immotech-web.s3.eu-central-1.amazonaws.com/images/Homepage-Section-1.png"
        opacity
        // left
        right
        LeftLine={1}
        mobileLeft
        title={translate("subscriptionMobileApp")}
        text={[
          { subtitle: translate("subscriptionMobileAppPremium"), medium: false },
          {
            subtitle: translate("subscriptionMobileAppFree"),
            paragraph: translate("subscriptionMobileAppFreeDescription"),
            medium: true
          },
          {
            subtitle: translate("subscriptionMobileAppPremiumModel"),
            paragraph: translate("subscriptionMobileAppPremiumModelDescription"),
            medium: true
          },
        ]}
      />

      {/* new ver 2 */}
      {/* <Section
      image = {`url(${require("../../assets/images/homepage-section-1.png")})`}
      title={translate("subscriptionMobileApp")}
      reversed
      invert
      isMobile={isMobileVersion}
      renderContent={()=>{return(<>
       <h3 className="regular subtitle-margin">{translate("subscriptionMobileAppPremium")}</h3>

          <div className="text-container">
            <h3 className="medium">{translate("subscriptionMobileAppFree")}</h3>
              <div className="line-and-text">
                  <div className="left-line"/>
                  <p className="tab-text">{translate("subscriptionMobileAppFreeDescription")}</p>
              </div> 
          </div>

          <div className="text-container">
            <h3 className="medium">{translate("subscriptionMobileAppPremiumModel")}</h3>
              <div className="line-and-text">
                  <div className="left-line"/>
                  <p className="tab-text">{translate("subscriptionMobileAppPremiumModelDescription")}</p>
              </div> 
          </div>
        </>)
        
      }}
      /> */}

      {/* old */}
      {/* <MainComponent
        image={language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/Subscription-section-2-en.png" : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/subscription-section-2.png"}
        left
        title={translate("subscriptionSymbolicFee")}
        text={[
          {
            subtitle: "",
            paragraph: translate("subscriptionSymbolicFeeDescription"),
          },
        ]}
      /> */}


      {/* new */}
      <MainComponent
        // image={language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/Subscription-section-2-en.png" : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/subscription-section-2.png"}
        image="https://immotech-web.s3.eu-central-1.amazonaws.com/images/functionalities-business_optimized.png"
        left
        title={translate("subscriptionBusiness")}
        text={[
          {
            subtitle: "",
            paragraph: translate("subscriptionBusinessDescription"),
          },
        ]}
        button={{ url: "https://mail.google.com/mail/u/0/?to=support@immotech.bg&fs=1&tf=cm", text: translate("subscriptionBusinessContact"), newWindowUrl: true }}
        // https://mail.google.com/mail/u/0/?to=support@immotech.bg&fs=1&tf=cm - opens in gmail
        // mailto:support@immotech.bg?subject=SendMail&body=Description - opens in mail
        // button={{ url: "/AboutUs", text: translate("subscriptionBusinessContact") }}
      />
      {/* <Section
      image={`url(${language === "en" ? "https://immotech-web.s3.eu-central-1.amazonaws.com/images/Subscription-section-3-en.png" : "https://immotech-web.s3.eu-central-1.amazonaws.com/images/subscription-section-3.png"})`}
      title={translate("subscriptionTwoBenefit")}
      isMobile={isMobileVersion}
      renderContent={()=>{
        return(<>
          <p>{translate("subscriptionTwoBenefitDescription")}</p>
        </>)
          }}  
      /> */}

      {/* old */}
      {/* <DownloadAppSection
        image={require("../../assets/images/abonament-svalete-min.png")}
      /> */}

      {/* new */}
      <DownloadAppSection
        // image={require("../../assets/images/download-section-background.png")}
        image="https://immotech-web.s3.eu-central-1.amazonaws.com/images/Download-section-background.png"
        title={translate("footerDownload")}
      />
    </div>
  );
}

export default Subscription;
