import React from "react";
import { connect } from "react-redux";
import { TitleComponent } from "../../components";
import { history } from "../../config/stores";
import { onSetBlogContent } from "../../actions";
import "./styles.scss";
import {translate} from "../../translations/localization"

function AboutUsComponent({ onSetBlogContent }) {
  return (
    <div className="about-us-text-component">
      <div className="dark-overlay">
        <div className="inner-text-container">
          <TitleComponent title={translate("aboutUsNinePercent")} />
          <p>
            {translate("aboutUsNinePercentShortDescription")}
          </p>
          <p>
            {translate("aboutUsNinePercentShortDescription2")}
          </p>
          <div
            className="read-more-button"
            onClick={() => {
              onSetBlogContent({
                title: translate("aboutUsNinePercent"),
                text: [
                  translate("aboutUsDescriptionP1"),
                  translate("aboutUsDescriptionP2"),
                  translate("aboutUsDescriptionP3"),
                  translate("aboutUsDescriptionP4"),
                  translate("aboutUsDescriptionP5"),
                  translate("aboutUsDescriptionP6"),
                  translate("aboutUsDescriptionP7"),
                  translate("aboutUsDescriptionP8"),
                ],
                image: require("../../assets/blog/1-min.jpg"),
                source:
                  "https://www.homekey.bg/%D0%BD%D0%BE%D0%B2%D0%B8%D0%BD%D0%B8_9_%D0%BE%D1%82_%D0%B6%D0%B8%D0%BB%D0%B8%D1%89%D0%B0%D1%82%D0%B0_%D1%83_%D0%BD%D0%B0%D1%81_%D1%81%D0%B0_%D0%BE%D1%82",
              });
              history.push("/SingleBlog");
              window.scrollTo({ top: 65, behavior: "smooth" });
            }}
          >
            <p>{translate("aboutUsLearnMoreButton")}</p>
            <div className="green-arrow-right" />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  onSetBlogContent: (payload) => dispatch(onSetBlogContent(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUsComponent);
