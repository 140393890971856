export const redirectPath = (currentPath) => {
    const { hostname } = window.location;

    if (hostname.includes('herokuapp.com')) {
      const redirectUrl = `https://www.immotech-app.com${currentPath}`;
      return window.location.replace(redirectUrl);
  }
  else {
    return currentPath
  }
}